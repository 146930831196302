import React, { useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const searchOptions = {
  location: new window.google.maps.LatLng(48.8566, 2.3522), // Coordonnées approximatives du centre de Paris
  radius: 10000, // Rayon en mètres (20 km est une valeur approximative pour couvrir l'Île-de-France)
  types: ['address'], // Restreindre les résultats aux adresses
  componentRestrictions: { country: 'fr' },
};
export default function Address ({setSearchAdress, searchAdress}) {


  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setSearchAdress(value);
    console.log('Adresse sélectionnée:', value);
    console.log('Coordonnées géographiques:', latLng);
  };

  return (
    <div>
      <PlacesAutocomplete
        value={searchAdress}
        onChange={setSearchAdress}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
          <FloatingLabel label="Adresse *" controlId="floatingFirstName">
                    <Form.Control {...getInputProps({ placeholder: 'Saisissez votre adresse...',  style: {border: 'none', borderBottom: '1px solid #008080', borderRadius: '0px'},})}/>
                  </FloatingLabel>
 

            <div>
              {loading ? <div>Loading...</div> : null}

              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#d1d1d1' : '#ffffff',
                  cursor: 'pointer',
                };

                return (

                  <div {...getSuggestionItemProps(suggestion, { style })} className='my-2'>
                    {suggestion.description}
                  </div>
              
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};
