import React, {useContext, useEffect, useState} from 'react';
import Navbar from "./components/Navbar";
import { Routes, Route,Navigate } from "react-router-dom";
import "./style/index.css";
import Profil from './pages/private/profil/Profil'
import Invoices from './pages/private/invoices/Invoices';
import Orders from './pages/private/orders/Orders';
import Connexion from './pages/login/connexion';
import Page404 from './pages/pageErreur/erreur404';
import { UserContext } from './context/userContext';
import UnderAccount from './pages/private/underAccount/UnderAccount';
import OrdersUnderAccount from './pages/private/underAccount/Order_underAccount';
import { collection, doc, onSnapshot, query, orderBy, where } from 'firebase/firestore';
import { db } from './firebase-config';

function App() {
	const [navVisible, showNavbar] = useState(false);
	const {currentUser } = useContext(UserContext);
	const [customer, setCostumer] = useState({});
	const [underAccount, setUnderAccount] = useState([])
	const [isLoading, setIsLoading] = useState(true);

	function loadGoogleMapsScript() {
		const googleMapsScript = document.createElement('script');
		googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_FIREBASE_API_KEY}&libraries=places`;
		googleMapsScript.async = true;
		googleMapsScript.defer = true;
		document.body.appendChild(googleMapsScript);
	  }
	  

	useEffect(()=> {
		if(window.innerWidth > 450){
			showNavbar(true)
		}
		if (!window.google) {
			loadGoogleMapsScript();
		  }
	}, [])

	useEffect(() => {
		if(currentUser){
			const unsubscribe = onSnapshot(doc(db, "corporate", currentUser.uid), (doc) => {
				setCostumer(doc.data());
				setIsLoading(false);
			});
		  
			  return () => unsubscribe(); 
		}
	}, []);

	useEffect(() => {
		if(currentUser){
			const unsubscribe = onSnapshot(doc(db, "corporate", currentUser.uid), (doc) => {
				setCostumer(doc.data());
				setIsLoading(false);
			});
		  
			  return () => unsubscribe(); 
		}
	}, [currentUser]);

	useEffect(() => {
		if(currentUser){
			const q = query(collection(db, "customers_corporate_underAccount"), where("id_company", "==", currentUser.uid), orderBy("date_create", "desc"));
			const unsubscribe = onSnapshot(q, (querySnapshot) => {
			  const NewunderAccount = [];
				querySnapshot.forEach((doc) => {
					NewunderAccount.push({ ...doc.data(), id: doc.id });
		  });
		  setUnderAccount(NewunderAccount);
		  });
		
			return () => unsubscribe(); 
		}
	  }, []);

	  useEffect(() => {
		if(currentUser){
			const q = query(collection(db, "customers_corporate_underAccount"), where("id_company", "==", currentUser.uid), orderBy("date_create", "desc"));
			const unsubscribe = onSnapshot(q, (querySnapshot) => {
			  const NewunderAccount = [];
				querySnapshot.forEach((doc) => {
					NewunderAccount.push({ ...doc.data(), id: doc.id });
		  });
		  setUnderAccount(NewunderAccount);
		  });
		
			return () => unsubscribe(); 
		}
	  }, [currentUser]);

	return (
			<div className="App">
				{currentUser && <Navbar visible={ navVisible } show={ showNavbar } uid={currentUser.uid} />}
				<Routes>
					<Route path="/" element={currentUser ? <OrdersUnderAccount customer={customer} underAccount={underAccount} stl={navVisible} uid={currentUser.uid} show={ showNavbar }/> : <Connexion />} />
					<Route path='/private/profile' element={currentUser ? <Profil stl={navVisible} uid={currentUser.uid} show={ showNavbar } /> : <Navigate to="/" />} />
					<Route path='/private/invoices' element={currentUser ? <Invoices stl={navVisible} uid={currentUser.uid} show={ showNavbar }/> : <Navigate to="/" />}/>
					<Route path='/private/underAccount' element={currentUser ? <UnderAccount stl={navVisible} uid={currentUser.uid} show={ showNavbar } customer={customer}/> : <Navigate to="/" />} />
					{/* <Route path='/private/orders' element={currentUser && show ? <Orders stl={navVisible} uid={currentUser.uid} show={ showNavbar }/> : <Navigate to="/" />}/> */}
					<Route path='/private/orders' element={currentUser ? <OrdersUnderAccount customer={customer} underAccount={underAccount} stl={navVisible} uid={currentUser.uid} show={ showNavbar }/> : <Navigate to="/" />}/>
					{/* <Route path='/private/orders_underAccount' element={currentUser ? <OrdersUnderAccount customer={customer} underAccount={underAccount} stl={navVisible} uid={currentUser.uid} show={ showNavbar }/> : <Navigate to="/" />}/> */}
					<Route path='*' element={<Page404 stl={navVisible} show={ showNavbar }/>} />
				</Routes>
			</div>
  );
}

export default App;
