import React, { useContext, useRef, useState } from "react";
import {Button, Col, Container, Form, Row } from "react-bootstrap";
import { UserContext } from "../../context/userContext";
import "./connexion.css";
import ModalPopup from "./ModalPopup";

function Connexion() {
    const { signIn } = useContext(UserContext);

    const inputs = useRef([]);
    const [modalOpen, setModalOpen] = useState(false)


    const addInputs = (el) => {
      if (el && !inputs.current.includes(el)) {
        inputs.current.push(el);
      }
    };
    const handleForm = async (e) => {
      e.preventDefault();
        await signIn(
          inputs.current[0].value,
          inputs.current[1].value
      );

    };
    return (
    <>
      <Container className="mx-auto mt-5 pt-5">
        <img
					src={require("../../assets/Images/CLEAMY.png")}
					alt="logo"
          className="img-fluid rounded mx-auto d-block mt-md-4 px-5 px-md-0 mt-4"
          width='400px'
				/>
         <Row className="mt-4 mt-md-5 mx-auto">
                <Col lg={5} md={6} sm={12} className="p-5 m-auto shadow-sm rounded-lg">
                    <form onSubmit={handleForm}>
                        <Form.Group role="form">
                            <Form.Label className="pb-2" style={{fontWeight: '600'}}>E-mail </Form.Label>
                            <Form.Control type="email" placeholder="Saisir votre adresse e-mail" className="px-2 py-1" required ref={addInputs}/>

                            <Form.Label className="mt-3 pb-2" style={{fontWeight: '600'}}>Mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Saisir votre mot de passe" className="px-2 py-1" required ref={addInputs}/>


                        <div className="d-grid gap-2 mt-5">
                          <Button variant="btn btn-block text-white" style={{background: '#008080'}} className="px-2 py-1" type="submit">
                              Se connecter
                          </Button>
                        </div>
                        </Form.Group>
                    </form>
                    <p className="pt-2" style={{textAlign: 'right', textDecoration: 'underline', cursor:'pointer', color: '#25316D'}}  onClick={() => setModalOpen(true)}>Mot de passe oublié ?</p>
                </Col>
            </Row>
      </Container>
      {modalOpen && <ModalPopup setOpenModal={setModalOpen} />}
    </>
);
}

export default Connexion;