import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../firebase-config';
import AdminDataService from './admin.services'
import { useSnackbar } from "notistack";

export const UserContext = createContext()

export function UserContextProvider(props){
    const { enqueueSnackbar } = useSnackbar();
    const [currentUser, setCurrentUser] = useState();
    const [loadingData, setLoadingData] = useState(true);
    const [loadingLogout, setLoadingLogout] = useState(false);

    const logOut = async () => {
        try {
        setLoadingLogout(true);
        await signOut(auth);
        } catch {
        alert("Un problème est survenu. Veuillez vérifier votre connexion internet et recommencer.");
        } finally {
        setLoadingLogout(false);
        }
    };
    const signIn = (email, pwd) => {
        signInWithEmailAndPassword(auth, email, pwd).catch((err) => {
        enqueueSnackbar("L'adresse e-mail ou le mot de passe que vous avez saisi(e) est incorrect.", {
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
        });
        });
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
          if (currentUser) {
            const snapDoc = await AdminDataService.getCustomer(currentUser.uid);
            if (snapDoc.exists()) {
              setCurrentUser(currentUser);
              setLoadingData(false);
            } else {
              setCurrentUser(null);
              await logOut();
              enqueueSnackbar("L'adresse e-mail ou le mot de passe que vous avez saisi(e) est incorrect.", {
                variant: 'error',
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                autoHideDuration: 3000,
              });
            }
          } else {
            setCurrentUser(null);
            setLoadingData(false);
          }
        });
        return () => unsubscribe();
      }, []);

    return(
        <UserContext.Provider value={{currentUser, signIn, loadingData, loadingLogout }}>
            {!loadingData && props.children}
        </UserContext.Provider>
    )
}