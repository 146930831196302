import React, { useEffect, useState } from 'react';
import '../../../style/index.css';
import { FaFileDownload } from "react-icons/fa";
import { db } from '../../../firebase-config';
import { collection, query, where, orderBy, onSnapshot } from "firebase/firestore";


export default function Invoices(props) {
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(invoices.length / 10);
  const changePage = (direction) => {
    setCurrentPage((prevPage) => {
      let newPage = prevPage + direction;
      if (newPage < 1) {
        newPage = 1;
      } else if (newPage > totalPages) {
        newPage = totalPages;
      }
      return newPage;
    });
  };
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }

  useEffect(() => {
    const q = query(collection(db, "corporate_invoice"), where("user", "==", props.uid), orderBy("date", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const invoices = [];
        querySnapshot.forEach((doc) => {
        invoices.push(doc.data());
  });
  setInvoices(invoices);
  });

    return () => unsubscribe(); 
  }, []);

  return (
      <div className={!props.stl ? "page" : "page page-with-navbar"} onClick={() => props.show(false)}>
        <p className=' m-4 mb-1 h3' style={{fontWeight: 'bold'}}>Mes factures</p>
        <div className='mx-auto' style={{height: '2px', width: '97%', background:'#F1F1F1'}}></div>

        {invoices.length === 0 ? <p className='text-center my-3 mx-auto text-secondary' style={{fontStyle: 'italic'}}>Votre compte ne présente pas encore de facture.</p> : <table className="table mx-auto mt-5 tableInvoice" style={{width: '90%'}}>
  <thead>
    <tr >
      <th scope="col" className='col-md-auto p-2' style={{color: '#303030'}}>Date d'émission</th>
      <th scope="col" className='col-md-auto p-2 text-center' style={{color: '#303030'}}>Numéro de facture</th>
      <th scope="col" className='col-md-auto p-2 text-center' style={{color: '#303030'}}>Montant TTC</th>
      <th scope="col" className='col-md-auto p-2 text-center' style={{color: '#303030'}}>Factures</th>
    </tr>
  </thead>
  <tbody>
        
    {invoices.slice(startIndex, endIndex).map((doc) => {
      return(
        <tr key={doc.id}>
          <td style={{color: '#8f8f8f'}} className=' p-2'>{formatDate(doc.date.toDate().toISOString())}</td>
          <td style={{color: '#8f8f8f'}} className=' p-2 text-center'>{doc.numeroOrder}</td>
          <td style={{color: '#8f8f8f'}} className=' p-2 text-center'>{Number.parseFloat(doc.ttc).toFixed(2)} €</td>
          <td style={{color: '#8f8f8f', cursor:"pointer"}} className='text-center p-2'>
          <a href={doc.lien} target="_blank" rel="noopener noreferrer">
          <FaFileDownload size={22} color='#008080'/>
        </a>
        </td>
        </tr>

      )
    })}
    </tbody>
</table>}
 <div className='w-100 mb-5 mt-4'>
            <div className="d-flex justify-content-end align-items-center mx-4 mb-5">
            <button onClick={() => changePage(-1)} disabled={currentPage === 1} className='btn ' style={{color: '#008080', background: '#F1F1F1'}}>
          Précédent
        </button>
        <span className='mx-2'>
          {currentPage} / {totalPages}
        </span>
        <button
          className='btn ' style={{color: '#008080', background: '#F1F1F1'}}
          onClick={() => changePage(1)}
          disabled={currentPage === totalPages}
        >
          Suivant
        </button>
        </div>
</div>
      </div>
    );
}