import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { UserContextProvider } from './context/userContext';
import App from './App';
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SnackbarProvider maxSnack={1} anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}>
  <BrowserRouter>
  <UserContextProvider>
    <App />
  </UserContextProvider>
</BrowserRouter>
</SnackbarProvider>
);
