import React, { useEffect, useState } from 'react';
import {
	FaUserAlt,
    FaReceipt,
	FaAngleRight,
	FaAngleLeft,
	FaShoppingCart,
	FaSignOutAlt,
	FaBars
} from 'react-icons/fa';
import { HiUsers } from 'react-icons/hi'
import { NavLink, useNavigate } from "react-router-dom";
import "../style/navbar.css";
import { auth } from '../firebase-config';
import { signOut } from 'firebase/auth';
import AdminDataService from '../context/admin.services'
import logoNav from '../assets/Images/logo.png'

const ICON_SIZE = 20;

function Navbar({visible, show, uid}) {
	const [customer, setCostumer] = useState([]);
	const navigate = useNavigate();

	const getCustomer = async () => {
		const docSnap = await AdminDataService.getCustomer(uid);
		setCostumer(docSnap.data());
	};
	useEffect(() => {
		getCustomer ();
	}, []);
    const logOut = async () => {
        try{
            await signOut(auth)
            navigate("/")
        } catch {
            alert("Un problème est survenu Veuillez verifier votre connexion internet et recommencer.")
        }
    }

	return (
		<>
			<div className="mobile-nav">
				<button
					className="mobile-nav-btn"
					onClick={() => show(!visible)}
				>
					<FaBars size={24}  />
				</button>
			</div>
			<nav className={!visible ? 'navbar' : ''}>
				<button
					type="button"
					className="nav-btn"
					onClick={() => show(!visible)}
				>
					{ !visible
						? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
				</button>
				<div>
					<NavLink
						onClick={() => show(!visible)}
						className="logo"
						to="/"
					>
							<img
								src={logoNav}
								alt="logo"
							/>
					</NavLink>
					<div className="links nav-top">
						<NavLink to="/private/orders" className="nav-link" onClick={() => show(!visible)}>
							<FaShoppingCart size={ICON_SIZE} />
							<span>Commandes</span>
						</NavLink>
						{(customer.underAccount || customer.homeDelivery) && <NavLink to="/private/underAccount" className="nav-link" onClick={() => show(!visible)}>
							<HiUsers size={ICON_SIZE} />
							<span>Mes sous-comptes</span>
						</NavLink>}
						<NavLink to="/private/invoices" className="nav-link" onClick={() => show(!visible)}>
							<FaReceipt size={ICON_SIZE} />
							<span>Factures </span>
						</NavLink>
						<NavLink to="/private/profile" className="nav-link" onClick={() => show(!visible)}>
							<FaUserAlt size={ICON_SIZE} />
							<span>Profil</span> 
						</NavLink>
					</div>
				</div>

				<div className="links">
					<button to="/Sign-out" className="nav-link bb" onClick={logOut} style={{background: 'transparent', border: 'none', cursor: 'pointer'}}>
						<FaSignOutAlt size={ICON_SIZE} />
						<span>Se déconnecter</span> 
					</button>
				</div>
			</nav>
		</>
  );
}

export default Navbar;
