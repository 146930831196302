import { collection, getDocs, query, where, orderBy, doc, getDoc, addDoc, updateDoc, deleteDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase-config";

const ordersCollectionRef = collection(db, "corporate_order");
const ordersCollectionRefBis = collection(db, "Orders_B2B");
class OrdereDataService {
  getAllOrders = (id) => {
    const q = query(ordersCollectionRefBis, where("user", "==", id), orderBy("date_collecte", "desc"));
    return getDocs(q);
  };
  getOrder= (id) => {
    const orderDoc = doc(db, "Orders_B2B", id);
    return getDoc(orderDoc);
  };
  addOrder= (newOrder) => {
    return addDoc(ordersCollectionRefBis, newOrder);
  };
  updateOrder = (newOrder, total, newNotes, idDoc) => {
    return updateDoc(doc(db, "Orders_B2B", idDoc), {
      under_user: newOrder,
      total_global: total,
    }).then(() => {
      const docRef = doc(db, "Orders_B2B", idDoc);
      newNotes.forEach((note) => {
        updateDoc(docRef, {
          notes: arrayUnion(note),
        });
      });
    });
  };

  deleteOrder = (newOrder, idDoc) => {
    return updateDoc(doc(db, "Orders_B2B", idDoc), {
      under_user: newOrder
    });
  };

  deleteOrderTotal = (idDoc) => {
    return deleteDoc(doc(db, "Orders_B2B", idDoc));
  };

  getAllSlots = () => {
    const q = query(collection(db, "slots"), orderBy("start"));
    return getDocs(q);
  };
}

export default new OrdereDataService();