import React, { useContext, useEffect, useState } from 'react'
import '../../../style/index.css'
import OrdereDataService from '../../../context/orders.services'
import Modal from 'react-bootstrap/Modal'
import { onSnapshot, query, Timestamp, where, orderBy, collection, doc } from 'firebase/firestore'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { Badge, FloatingLabel, Form } from 'react-bootstrap'
import { FiEdit } from 'react-icons/fi'
import { FaEye, FaPlus, FaTrash } from "react-icons/fa"
import { Dropdown, DropdownButton } from 'react-bootstrap';
import UnderAccountDataService from '../../../context/underAccount.services';
import { UserContext } from '../../../context/userContext';
import './dropdown.css'
import { useSnackbar } from 'notistack';
import { db } from '../../../firebase-config'
import Address from '../../../components/Address'
import { MdTaskAlt, MdOutlineAddTask } from 'react-icons/md'
import { differenceInMinutes, format } from 'date-fns'
import { fr } from 'date-fns/locale'
import PhoneInput from 'react-phone-input-2';

function getUniqueDatesByDayMonthYear(dates) {
  const uniqueDates = [];
  const dateMap = new Map();

  dates.forEach(date => {
    // Récupérer le jour, le mois et l'année de chaque date
    const day = date.start.toDate().getDate();
    const month = date.start.toDate().getMonth();
    const year = date.start.toDate().getFullYear();
    const dateKey = `${day}/${month}/${year}`;

    // Vérifier si cette date a déjà été vue
    if (!dateMap.has(dateKey)) {
      dateMap.set(dateKey, true);
      uniqueDates.push(date);
    }
  });

  return uniqueDates;
}

function getDatesByDayMonthYear(dates, targetDate) {
  const matchingDates = [];

  dates.forEach(date => {
    // Récupérer le jour, le mois et l'année de chaque date
    const day = date.start.toDate().getDate();
    const month = date.start.toDate().getMonth();
    const year = date.start.toDate().getFullYear();

    // Vérifier si cette date est égale à la date cible
  
      if (day === targetDate.start.toDate().getDate() &&
      month === targetDate.start.toDate().getMonth() &&
      year === targetDate.start.toDate().getFullYear()) {
    matchingDates.push(date);
  }
    

  });

  return matchingDates;
}

export default function OrdersUnderAccount (props) {
  const [showInfo, setShowInfo] = useState(true);
  const [orders, setOrders] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [cmpAdress, setCmpAdress] = useState(null)
  const [selectedUnderAccount, setSelectedUnderAccount] = useState(null)
  const [indexCustomer, setIndexCustomer] = useState(0)
  const [searchAdress, setSearchAdress] = useState('')
  const [cmpAddress, setCmpAddres] = useState('')
  const [customerData, setCustomerData] = useState(null);
  const [underAccountData, setUnderAccountData] = useState(null);



  const handleAddAdressCustomer = async (e) => {
    e.preventDefault();
    if(searchAdress === ''){
      enqueueSnackbar(`Veuillez saisir une adresse`, { 
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
       
      });
    }else if (
      underAccountData &&
      indexCustomer !== undefined &&
      underAccountData[indexCustomer] &&
      underAccountData[indexCustomer].addresses
    ){
      const tabAdress = [ ...underAccountData[indexCustomer].addresses];
      const newAddress = {
        adresse: searchAdress,
        complment: cmpAddress
      }
      tabAdress.push(newAddress);
      await UnderAccountDataService.updateAdressUnderAccount(tabAdress, underAccountData[indexCustomer].id);
        setSearchAdress('');
        setCmpAddres('');
        setShowAddAdress(false);
        setSelectedAddress(newAddress.adresse);
          }else {
            console.log('error')
            // Gérer le cas où underAccountData[indexCustomer] ou la propriété addresses n'existe pas
          }
  };
  const handleAddCustomer = async (e) => {
    e.preventDefault();
    if(firstNameUnderUser === '' && lastNameUnderUser === ''){
      enqueueSnackbar(`Veuillez saisir le nom et le prénom`, { 
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
       
      });
    }else{
      if(lastNameUnderUser === ''){
        enqueueSnackbar(`Veuillez saisir le nom`, { 
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          autoHideDuration: 3000,
         
        });
      }else{
        if(firstNameUnderUser === '') {
          enqueueSnackbar(`Veuillez saisir le prénom`, { 
            variant: 'error',
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            autoHideDuration: 3000,
           
          });
        }else {
          const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if(emailUnderUser.length > 0 && !regex.test(emailUnderUser)){
            enqueueSnackbar(`l'adresse email saisie n'est pas valide`, { 
              variant: 'error',
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              autoHideDuration: 3000,
             
            });
          }else{
            const newUnderUser = {
              date_create: new Date(),
              email: emailUnderUser,
              first_name: capitalizeFirstLetters(firstNameUnderUser),
              id_company: currentUser.uid,
              last_name: UpperCaseText(lastNameUnderUser),
              nb_orders: 0,
              status: 'Green',
              addresses: [],
              tel: firstPhone,
            }
            const docSnapshot = await UnderAccountDataService.addUnderUser(newUnderUser);
            setFirstNameUnderUser('');
            setFirstPhone('');
            setLastNameUnderUser('');
            setEmailUnderUser('');
            setShowAddUnderAccount(false);
            setSelectedUnderAccount(`${newUnderUser.first_name} ${newUnderUser.last_name}`);
            setSelectedUnderUser({ id: docSnapshot.id, ...newUnderUser });
            setSelectedAddress(null);
            setIndexCustomer(0); 
            setSearchAdress('')
          }
        }
      }
  
    }
  };

  useEffect(() => {
    if (props.customer) {
      setCustomerData(props.customer);
      
    }
  }, [props.customer]);


  useEffect(() => {
    if (props.underAccount) {
      setUnderAccountData(props.underAccount);
    }
  }, [props.underAccount]);

  useEffect(() => {
    const q = query(collection(db, "Orders_B2B"), where("user", "==", props.uid), orderBy("date_collecte", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const ordersB2B = [];
        querySnapshot.forEach((doc) => {
          ordersB2B.push({ ...doc.data(), id: doc.id});
  });
  setOrders(ordersB2B);
  });

    return () => unsubscribe(); 
  }, []);
  
  useEffect(() => {
    if(customerData && customerData.price){
      const unsub = onSnapshot(doc(db, "tarifs", customerData?.price), (doc) => {
        if (doc.exists) { // Vérifie si le document existe
          setItems({ ...doc.data(), id: doc.id });
        } else {
          console.log("Document does not exist");
        }
      });
  
      return () => unsub();
    }


  
}, [customerData]);





  const { currentUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPrestation, setSelectedPrestation] = useState('Standard')

  
  const [showAddUnderAccount, setShowAddUnderAccount] = useState(false)
  const [showAddAddress, setShowAddAdress] = useState(false)
  const [firstNameUnderUser, setFirstNameUnderUser] = useState('')
  const [showPhoneSelected, setShowPhoneSelected] = useState('')
  const [firstPhone, setFirstPhone] = useState('')
  const [lastNameUnderUser, setLastNameUnderUser] = useState('')
  const [emailUnderUser, setEmailUnderUser] = useState('')
  const [showAddOrder, setShowAddOrder] = useState(false)
  const [showConfirmed, setShowConfirmed] = useState(false)

  const [fullscreen, setFullscreen] = useState(true)
  const [selectedUnderUser, setSelectedUnderUser] = useState({});
  const [orderRecap, setOrderRecap] = useState({})
  const [requeteRetouche, setRequeteRetouche] = useState('');
  const [requeteCordonnerie, setRequeteCordonnerie] = useState('');
  const [requeteGlobal, setRequeteGlobal] = useState('');
  const [search, setSearch] = useState('');
  const [searchOrder, setSearchOrder] = useState('');
  const [numberOrder, setNumberOrder] = useState(false);
  const [pressingPlus, setPressingPlus] = useState(false);

  const [items, setItems] = useState({})
  const [show, setShow] = useState(false)
  const [order, setOrder] = useState({})
  const [underCustomer, setUnderCustomer] = useState(() => {
    if (order && order.under_user) {
      const processedUnderUser = order.under_user
        .map((item, index) => ({ ...item, initialIndex: index }))
        .filter((item) => item.creation_date !== undefined)
        .sort((a, b) => {
          return b.creation_date.toMillis() - a.creation_date.toMillis();
        });
       
      if (processedUnderUser.length > 0) {
        return processedUnderUser[0].initialIndex;
      } else {
        return 0
      }
    } else {
      return 0
    }
  })
  const [confirmedDelete, setConfirmedDelete] = useState('')
  const [options, setOptions] = useState([
    { id: 'Pressing', label: 'PRESSING / BLANCHISSERIE', selected: false, items: [] },
    { id: 'Retouche', label: 'RETOUCHERIE', selected: false, items: [] },
    { id: 'Cordonnerie', label: 'CORDONNERIE', selected: false, items: [] },
    { id: 'lk', label: 'LINGE AU KILO', selected: false, items: [] },
  ])

  const handleRequeteGlobal = (event) => {
    setRequeteGlobal(event.target.value);
  };
  const handleRequeteRetouche = (event) => {
    setRequeteRetouche(event.target.value);
  };
  const handleRequeteCordonnerie = (event) => {
    setRequeteCordonnerie(event.target.value);
  };

  const toggleOption = (index) => {
    const newOptions = [...options];
    newOptions[index].selected = !newOptions[index].selected;
    newOptions[index].items = [];
    setOptions(newOptions);
  }
  const addItem = (optionIndex) => {
    const newOptions = [...options];
    newOptions[optionIndex].items.push({
      article: '',
      price: 0,
      total_article: 1,
      total: 0,
    });
    setOptions(newOptions);
  }


  const calculateTotalSum = () => {
    return options.reduce((totalSum, option) => {
      const optionSum = option.items.reduce((sum, item) => sum + item.total, 0);
      return totalSum + optionSum;
    }, 0);
  };
  const forceInteger = (value) => {
    return parseInt(value, 10) || 1;
  };
  const filterListByDate = () => {
    return orders.filter((item) => {
      const itemDay = item.date_collecte.toDate().getDate();
      const itemMonth = item.date_collecte.toDate().getMonth();
      const itemYear = item.date_collecte.toDate().getFullYear();
      const dateDay = customerData?.slots_passages?.[0]?.toDate().getDate();
      const dateMonth = customerData?.slots_passages?.[0]?.toDate().getMonth();
      const dateYear = customerData?.slots_passages?.[0]?.toDate().getFullYear();
      return itemDay === dateDay && itemMonth === dateMonth && itemYear === dateYear;
    });
  };
  function findIndexOfSmallestetatSecond(array) {
    // Filtrer les éléments avec `number` non vide et convertir `number` en nombre
    const filteredArray = array
        .map((element, index) => ({ index, etat_second: Number(element.etat_second) }))
        .filter(element => !isNaN(element.etat_second) && element.etat_second !== 0);

    // Trouver l'indice de l'élément avec le plus petit `number`
    const result = filteredArray.reduce((acc, curr) => {
        return (acc === null || curr.etat_second < array[acc].etat_second) ? curr.index : acc;
    }, null);

    return result;
}
function areAllEtatSecondEmpty(array) {
  return array.every(element => element.etat_second === "");
}

  const handleSelect = (item) => {
    setSelectedUnderAccount(item);
    setShowAddAdress(false);
  };
  const handleSelectAddress = (item) => {
    setSelectedAddress(item);
  };
  const handleChange = (event) => {
    setSelectedPrestation(event.target.value);
  };
  const resetItemsAndSelection = (options) => {
    const array = [...options];
    return array.map((obj) => {
      return {
        ...obj,
        items: [],
        selected: false,
      };
    });
  };
  const onHideAddOrder = () => {
    setShowAddOrder(showAddOrder => false)
    setSelectedUnderAccount(selectedItem => null)
    setShowAddUnderAccount(false)
    setSelectedPrestation('Standard')
    setLastNameUnderUser('')
    setFirstNameUnderUser('')
    setFirstPhone('')
    setEmailUnderUser('')
    const newOptions = resetItemsAndSelection(options)
    setOptions(newOptions);
    setSelectedUnderUser({});
    initialiseService();
      setSlotCollecte(getUniqueDatesByDayMonthYear(slots.filter(date => {
    const now = new Date();
    const diff = (date.start.toDate() - now) / (1000 * 60 * 60);
    return diff >= 2;
  }))[0]);
  setIndexClick(0);
  setIndexClickHH(0);
  setIndexClickLivraison(0);
  setIndexClickHHLivraison(0);
  };
  const updateItem = (optionIndex, itemIndex, field, value) => {
    const newOptions = [...options];
    newOptions[optionIndex].items[itemIndex][field] = value;
  
    if (field === 'article') {
      const selectedItem = items[newOptions[optionIndex].id].find(
        (item) => item.name === value
      );
      if (selectedItem) {
        newOptions[optionIndex].items[itemIndex].price = selectedItem.priceTTC.standard;
      }
    }
  
    if (field === 'total_article') {
      newOptions[optionIndex].items[itemIndex].total_article = parseInt(value, 10);
    }
  
    newOptions[optionIndex].items[itemIndex].total =
      newOptions[optionIndex].items[itemIndex].price *
      newOptions[optionIndex].items[itemIndex].total_article;
  
    setOptions(newOptions);
  };
  const removeItem = (optionIndex, itemIndex) => {
    const newOptions = [...options];
    newOptions[optionIndex].items.splice(itemIndex, 1);
    setOptions(newOptions);
  };
  /**
   * Convertit chaque mot de la chaîne de caractères `text` en majuscules.
   *
   * @param {string} text - La chaîne de caractères à convertir.
   * @returns {string} - La chaîne de caractères modifiée avec chaque mot en majuscules.
   */
  const UpperCaseText = (text) => {
    return text
      .split(' ') // Divise la chaîne en un tableau de mots.
      .map((word) => word.toUpperCase()) // Convertit chaque mot en majuscules.
      .join(' '); // Rassemble les mots en une seule chaîne, séparés par des espaces.
  };
/**
 * Met la première lettre de chaque mot de la chaîne de caractères `str` en majuscule.
 *
 * @param {string} str - La chaîne de caractères à convertir.
 * @returns {string} - La chaîne de caractères modifiée avec la première lettre de chaque mot en majuscule.
 */
const capitalizeFirstLetters = (str) => {
  return str
    .split(' ') // Divise la chaîne en un tableau de mots.
    .map((word) =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ) // Met la première lettre de chaque mot en majuscule et le reste en minuscules.
    .join(' '); // Rassemble les mots en une seule chaîne, séparés par des espaces.
};


  const formatStringName = (str) => {
    const words = str?.split(' ');
  let formattedStr = '';

  words?.forEach((word, index) => {
    const isFirstWord = index === 0;
    const isLastWord = index === words.length - 1;

    if (isFirstWord) {
      formattedStr += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' ';
    } else if (isLastWord) {
      formattedStr += word.toUpperCase();
    } else {
      formattedStr += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' ';
    }
  });

  return formattedStr;
  }
  const confirmedOrder = () => {
 

//Sous Compte : PLS LIV

    if(customerData?.underAccount || customerData?.homeDelivery){
      if (Object.keys(selectedUnderUser).length === 0) {
        enqueueSnackbar('Veuillez séléctionner un compte.', { 
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          autoHideDuration: 3000,
          className: 'text-center',
         
        });
        return;
      }
    }
    if (!selectedAddress && customerData?.homeDelivery) {
      enqueueSnackbar('Veuillez séléctionner une adresse.', { 
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        className: 'text-center',
      });
      return;
    }



    const selectedOptions = options.filter((option) => option.selected);
    if (selectedOptions.length === 0) {
      enqueueSnackbar('Veuillez sélectionner au moins une option (pressing, retoucherie, cordonnerie, ou linge au kilo) pour valider votre panier.', { 
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        className: 'text-center',
       
      });
      return;
    }
    const validAddItem = selectedOptions.every((option) => {
      return (
        option.items.length > 0
      );
    });
    if (!validAddItem ) {
      enqueueSnackbar("Veuillez ajouter au moins un article à l'option sélectionnée avant de valider votre panier.", { 
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
        className: 'text-center',
       
      });
      return;
    }
    // Vérifiez que chaque option sélectionnée a au moins un article
    const validSelection = selectedOptions.every((option) => {
      return (
        option.items.every(
          (item) => item.article !== '' && item.total_article !== '' && item.total_article > 0 &&
          item.price > 0 &&
          item.total > 0 
        )
      );
    });
    if (!validSelection ) {
      enqueueSnackbar('Veuillez sélectionné article au minimum.', { 
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
       
      });;
      return;
    }
    const recap = options.reduce((result, option) => {
      result[option.id] = option;
      return result;
    }, {});
    const dateC = slotCollecte ? slotCollecte : premiereDate;
    const dateL = slotLivraison ? slotLivraison : premiereDateLivraison;

    const newUnder_user = {
      id_userUnder: customerData.underAccount || customerData?.homeDelivery ? selectedUnderUser.id : currentUser.uid,
      name: customerData.underAccount || customerData?.homeDelivery ? `${selectedUnderUser.first_name} ${selectedUnderUser.last_name}` : customerData?.nameCompany,
      address: customerData?.homeDelivery ? selectedAddress : '',
      service: showInfo ? 'Standard' : 'Prime',
      recap_driver: {
        Cordonnerie: recap.Cordonnerie.items,
        Pressing: recap.Pressing.items,
        Retouche: recap.Retouche.items,
        lk: recap.lk.items
      },
      recap_presta: {
        Cordonnerie: [],
        Pressing: [],
        Retouche: [],
        lk: []
      },
      recap_driverBis: {
        Cordonnerie: [],
        Pressing: [],
        Retouche: [],
        lk: []
      },
      total: calculateTotalSum(),
      quality: selectedPrestation,
      number_order: 'CP-' + generate(),
      date_livraison: customerData?.homeDelivery ? dateL.start : customerData.slots_passages[1],
      slot_livraison_end: customerData?.homeDelivery ? dateL.end : '',
      etat_second: '',
      date_creation: Timestamp.fromDate(new Date()),
      driver: '',
    }
    const newOrder = {
      date_collecte: customerData?.homeDelivery ? dateC?.start : customerData?.slots_passages[0],
      date_creation_order: Timestamp.fromDate(new Date()),
      slot_collecte_end: customerData?.homeDelivery ? dateC?.end : '',
      etat_commande: 1,
      facture_id: '',
      under_user: [newUnder_user],
      total_global: calculateTotalSum(),
      user: props.uid,
      notes: [],
    }
    setOrderRecap(newOrder);
    setShowConfirmed(showConfirmed =>true)

  }
  const showSelectedOptions = async() => {

    if(orderRecap?.under_user?.[0]?.recap_driver?.Retouche.length > 0 && requeteRetouche === ''){
      enqueueSnackbar('Veuillez saisir le détail de la retouche.', { 
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
       
      });
    }else if(orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie.length > 0 && requeteCordonnerie === ''){
      enqueueSnackbar('Veuillez saisir le détail de la cordonnerie.', { 
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        autoHideDuration: 3000,
       
      });
    }else{
      const newOrderUpdate = { ...orderRecap};
      if(orderRecap?.under_user?.[0]?.recap_driver?.Retouche &&
        orderRecap?.under_user?.[0]?.recap_driver?.Retouche.length > 0){
        const noteRetouche = {
          ref: orderRecap?.under_user?.[0]?.number_order,
          title: 'Retoucherie',
          public: true,
          cleanning: '',
          emmergency: 'normal',
          comment: requeteRetouche,
          creation_date: Timestamp.fromDate(new Date()),
          author: 'user',
          pressing: pressingPlus
        }
        newOrderUpdate.notes.push(noteRetouche)
      }
      if(orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie &&
        orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie.length > 0){
        const noteCordonnerie = {
          ref: orderRecap?.under_user?.[0]?.number_order,
          title: 'Cordonnerie',
          public: true,
          cleanning: '',
          emmergency: 'normal',
          comment: requeteCordonnerie,
          creation_date: Timestamp.fromDate(new Date()),
          author: 'user',
          pressing: pressingPlus
        }
        newOrderUpdate.notes.push(noteCordonnerie)
      }
      if(requeteGlobal !== ''){
        const noteGlobal = {
          ref: orderRecap?.under_user?.[0]?.number_order,
          title: 'Global',
          public: true,
          cleanning: '',
          emmergency: 'normal',
          comment: requeteGlobal,
          creation_date: Timestamp.fromDate(new Date()),
          author: 'user',
          pressing: pressingPlus
        }
        newOrderUpdate.notes.push(noteGlobal)
      }
    if(customerData?.homeDelivery && customerData?.underAccount){
      await OrdereDataService.addOrder(newOrderUpdate)
    }else{
      if(filterListByDate().length === 0){
        await OrdereDataService.addOrder(newOrderUpdate)
       }
       if(filterListByDate().length === 1){
         const newUnder_userCopy = {...filterListByDate()[0]};
         newUnder_userCopy.under_user.push(newOrderUpdate?.under_user?.[0]);
         newUnder_userCopy.total_global += calculateTotalSum();
         await OrdereDataService.updateOrder(newUnder_userCopy.under_user, newUnder_userCopy.total_global, newOrderUpdate.notes, newUnder_userCopy.id);
       }
    }

   
      setRequeteCordonnerie('')
      setRequeteRetouche('')
      setRequeteGlobal('')
      setPressingPlus(false)
      setNumberOrder(true)
      onHideAddOrder()
      setIndexCustomer(0)
    }
    
    if(!(orderRecap?.under_user?.[0]?.recap_driver?.Retouche.length > 0 && requeteRetouche === '') && !(orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie.length > 0 && requeteCordonnerie === '') && customerData?.homeDelivery){
      setShowConfirmed(false); setNumberOrder(false)
    }
  };
  const formatDate2 = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }
  const generate = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
      .toUpperCase()
  }
  const getLivraisonDate = (data) => {
    if (data && data.under_user) {
      const arrayWithDateLivraison = data.under_user.filter(obj => obj.hasOwnProperty("date_livraison"));
      const arrayWithoutDateLivraison = data.under_user.filter(obj => !obj.hasOwnProperty("date_livraison"));

      arrayWithDateLivraison.sort((a, b) => b.date_livraison.toMillis() - a.date_livraison.toMillis());

      return arrayWithDateLivraison.concat(arrayWithoutDateLivraison);
    }

    return [];
  }
 
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }
  const getStatusOrder = (etat) => {
    let etatActuel = ''
    switch (etat) {
      case 1 : etatActuel = 'En cours de récupération'; break
      case 2 : etatActuel = 'Réceptionnée'; break
      case 3 : etatActuel = 'En cours de traitement'; break
      case 4 : etatActuel = 'En cours de livraison'; break
      case 5 : etatActuel = 'Livrée'; break
      case 6 : etatActuel = 'Annulée'; break
      default : etatActuel = ''
    }
    return etatActuel
  }
  const hind = () => {
    setShow(false)
    setUnderCustomer(0)
  }
  const handleRecap = (index) => {
    setUnderCustomer(index)
  }
  const getOrder = async (id) => {
    const docSnap = await  OrdereDataService.getOrder(id);
      setOrder({ id, ...docSnap.data() });
  }
  const handleShow = async (orderId) => {
    setUnderCustomer(0)
      getOrder(orderId)
      if (order && order.under_user) {
        const processedUnderUser = order.under_user
          .map((item, index) => ({ ...item, initialIndex: index }))
          .filter((item) => item.date_creation !== undefined)
          .sort((a, b) => {
            return b.date_creation.toMillis() - a.date_creation.toMillis();
          });
         
        if (processedUnderUser.length > 0) {
          setUnderCustomer(processedUnderUser[0].initialIndex);
        } else {
          setUnderCustomer(0);
        }
      } else {
        setUnderCustomer(0);
      }
      setFullscreen(true)
      setShow(true)
  }
  const deleteOrder = async(index) => {

    const newOrderDelete = [...order.under_user]
    newOrderDelete.splice(index, 1)
    await OrdereDataService.deleteOrder(newOrderDelete, order.id)
    getOrder(order.id)

    if(order.under_user.length === 1){
      await OrdereDataService.deleteOrderTotal(order.id);
   
      setShow(false)
    }
    setConfirmedDelete(confirmedDelete => '')
    enqueueSnackbar(`La commande ${order.under_user[index].number_order} à bien été annulée`, { 
      variant: 'success',
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      autoHideDuration: 3000,
      className:'text-center'
     
    });
  }
  const filteredOrdersPressingCustomer = (doc) => doc.under_user?.filter(user => user.recap_driver.Pressing.length > 0).length;
  const filteredOrdersRetoucheCustomer = (doc) => doc.under_user?.filter(user => user.recap_driver.Retouche.length > 0).length;
  const filteredOrdersCordonnerieCustomer = (doc) => doc.under_user?.filter(user => user.recap_driver.Cordonnerie.length > 0).length;
  const filteredOrdersLkCustomer = (doc) => doc.under_user?.filter(user => user.recap_driver.lk.length > 0).length;
  const filteredOrdersPressing = filterListByDate()[0]?.under_user?.filter(user => user.recap_driver.Pressing.length > 0).length;
  const filteredOrdersRetouche = filterListByDate()[0]?.under_user?.filter(user => user.recap_driver.Retouche.length > 0).length;
  const filteredOrdersCordonnerie = filterListByDate()[0]?.under_user?.filter(user => user.recap_driver.Cordonnerie.length > 0).length;
  const filteredOrdersLk = filterListByDate()[0]?.under_user?.filter(user => user.recap_driver.lk.length > 0).length;



  useEffect(() => {
     if (order && order.under_user) {
        const processedUnderUser = order.under_user
          .map((item, index) => ({ ...item, initialIndex: index }))
          .filter((item) => item.date_creation !== undefined)
          .sort((a, b) => {
            return b.date_creation.toMillis() - a.date_creation.toMillis();
          });
 
        if (processedUnderUser.length > 0) {
          setUnderCustomer(processedUnderUser[0].initialIndex);
        } else {
          setUnderCustomer(0);
        }
      } else {
        setUnderCustomer(0);
      }
  }, [order])
  // const deleteOrders = async () => {
  //   const newUnder_user = {
  //     id_userUnder: '',
  //     name: '',
  //     recap_driver: {
  //       Cordonnerie: [],
  //       Pressing: [],
  //       Retouche: [],
  //       lk: []
  //     },
  //     recap_presta: {
  //       Cordonnerie: [],
  //       Pressing: [],
  //       Retouche: [],
  //       lk: []
  //     },
  //     recap_driverBis: {
  //       Cordonnerie: [],
  //       Pressing: [],
  //       Retouche: [],
  //       lk: []
  //     },
  //     total: 0,
  //     quality: '',
  //     number_order: '',
  //     date_livraison: customer.slots_passages[1],
  //     etat_second: '',
  //     date_creation: Timestamp.fromDate(new Date()),
  //     driver: '',
  //   }
  //   const newOrder = {
  //     date_collecte: customer.slots_passages[0],
  //     etat_commande: 4,
  //     facture_id: '',
  //     under_user: [newUnder_user],
  //     total_global: 0,
  //     user: props.uid,
  //     notes: [],
  //   }
  //   await OrdereDataService.addOrder(newOrder)
  // }
  // const deletePassage = async () => {
  //   const dateAct = new Date()
  //   if (customer.slots_passages[0].toDate() > dateAct && filterListByDate().length === 0) {
  //     try {
  //       getCustomer()
  //       const tab = []
  //       for (let i = 1; i < customer.slots_passages.length; i++) {
  //         tab.push(customer.slots_passages[i])
  //       }
  //       const nvxSlot = date.addDays(customer.slots_passages[0].toDate(), +7)
  //       tab.push(Timestamp.fromDate(nvxSlot))
  //       await AdminDataService.updateSlotCostumer(props.uid, tab)
  //       deleteOrders()
  //     } catch (err) {}
  //   }
  // }
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageEnLigne, setCurrentPageEnLigne] = useState(1);

  const totalPages = Math.ceil(orders.filter(order => order.etat_commande === 6 || order.etat_commande === 5).filter(doc => {
    return doc.under_user.some(user =>
      searchOrder.toLowerCase() === ''
        ? true
        : (user.name.toLowerCase().includes(searchOrder.toLowerCase()) || user.number_order.toLowerCase().includes(searchOrder.toLowerCase())));
  }).length / 5);
  const totalPagesEnLigne = Math.ceil(orders.filter(order => { 
    return order.etat_commande !== 6 && order.etat_commande !== 5 && filterListByDate()[0]?.id !== order.id}).length / 5);

  const changePage = (direction) => {
    setCurrentPage((prevPage) => {
      let newPage = prevPage + direction;
      if (newPage < 1) {
        newPage = 1;
      } else if (newPage > totalPages) {
        newPage = totalPages;
      }
      return newPage;
    });
  };
  const changePageEnLigne = (direction) => {
    setCurrentPage((prevPage) => {
      let newPage = prevPage + direction;
      if (newPage < 1) {
        newPage = 1;
      } else if (newPage > totalPages) {
        newPage = totalPages;
      }
      return newPage;
    });
  };

  const startIndex = (currentPage - 1) * 5;
  const endIndex = startIndex + 5;
  const startIndexEnligne = (currentPage - 1) * 5;
  const endIndexEnLigne = startIndex + 5;

  //---------------------------------------------------

  const [modalShowCollecte, setModalShowCollecte] = useState(false);
  const [modalShowLivraison, setModalShowLivraison] = useState(false);
  const [slots, setSlots] = useState([]);

  const [slotCollecte, setSlotCollecte] = useState(getUniqueDatesByDayMonthYear(slots.filter(date => {
    const now = new Date();
    const diff = (date.start.toDate() - now) / (1000 * 60 * 60);
    return diff >= 2;
  }))[0]);

  const premiereDate = slots.filter(date => {
    const now = new Date();
    const diff = (date.start.toDate() - now) / (1000 * 60 * 60);
    return diff >= 2;
  })[0];

  const [slotLivraison, setSlotLivraison] = useState(getUniqueDatesByDayMonthYear(slots.filter(date => {
    // Convert the dates to Unix timestamps
   const filterTime = slotCollecte ? new Date(slotCollecte.start.toDate()).getTime() : new Date(premiereDate.start.toDate()).getTime();
   const dateTime = new Date(date.start.toDate()).getTime();

   // Calculate the difference in milliseconds
   const diffMs = dateTime - filterTime;

   // Calculate the difference in hours
   const diffHours = diffMs / (1000 * 60 * 60);

   // Check if the difference is at least 48 hours (2880 minutes)
   return showInfo ? diffHours >= 48 : diffHours >= 24;
  }))[0]);

  const premiereDateLivraison = getUniqueDatesByDayMonthYear(slots.filter(date => {
    // Convert the dates to Unix timestamps
   const filterTime = slotCollecte ? new Date(slotCollecte.start.toDate()).getTime() : new Date(premiereDate.start.toDate()).getTime();
   const dateTime = new Date(date.start.toDate()).getTime();

   // Calculate the difference in milliseconds
   const diffMs = dateTime - filterTime;

   // Calculate the difference in hours
   const diffHours = diffMs / (1000 * 60 * 60);

   // Check if the difference is at least 48 hours (2880 minutes)
   return showInfo ? diffHours >= 48 : diffHours >= 24;
  }))[0];

  const uniqueDates = getUniqueDatesByDayMonthYear(slots.filter(date => {
    const now = new Date();
    const diff = (date.start.toDate() - now) / (1000 * 60 * 60);
    return diff >= 2;
  }));

  const matchingDates = getDatesByDayMonthYear(slots.filter(date => {
    const now = new Date();
    const diff = (date.start.toDate() - now) / (1000 * 60 * 60);
    return diff >= 2;
  }), slotCollecte ? slotCollecte : premiereDate);

  const [indexClick, setIndexClick] = useState(0);
  const [indexClickHH, setIndexClickHH] = useState(0);
  const [indexClickLivraison, setIndexClickLivraison] = useState(0);
  const [indexClickHHLivraison, setIndexClickHHLivraison] = useState(0);

  const uniqueDatesLivraison = getUniqueDatesByDayMonthYear(slots.filter(date => {
    // Convert the dates to Unix timestamps
    const filterTime = slotCollecte ? new Date(slotCollecte.start.toDate()).getTime() : new Date(premiereDate.start.toDate()).getTime();
    const dateTime = new Date(date.start.toDate()).getTime();

    // Calculate the difference in milliseconds
    const diffMs = dateTime - filterTime;

    // Calculate the difference in hours
    const diffHours = diffMs / (1000 * 60 * 60);

    // Check if the difference is at least 48 hours (2880 minutes)
    return showInfo ? diffHours >= 48 : diffHours >= 24;
  }));

  const matchingDatesLivraison = getDatesByDayMonthYear(slots.filter(date => {
    // Convert the dates to Unix timestamps
    const filterTime = slotCollecte ? new Date(slotCollecte.start.toDate()).getTime() : new Date(premiereDate.start.toDate()).getTime();
    const dateTime = new Date(date.start.toDate()).getTime();

    // Calculate the difference in milliseconds
    const diffMs = dateTime - filterTime;

    // Calculate the difference in hours
    const diffHours = diffMs / (1000 * 60 * 60);

    // Check if the difference is at least 48 hours (2880 minutes)
    return showInfo ? diffHours >= 48 : diffHours >= 24;
  }), slotLivraison ? slotLivraison : premiereDateLivraison);

  const initialiseService = () => {
    setShowInfo(showInfo => true)
  }

  const initialiseService2 = () => {
    setShowInfo(showInfo => false)
  }

  useEffect(() => {
    setSlotLivraison(getUniqueDatesByDayMonthYear(slots.filter(date => {
        // Convert the dates to Unix timestamps
       const filterTime = slotCollecte ? new Date(slotCollecte.start.toDate()).getTime() : new Date(premiereDate.start.toDate()).getTime();
       const dateTime = new Date(date.start.toDate()).getTime();
    
       // Calculate the difference in milliseconds
       const diffMs = dateTime - filterTime;
    
       // Calculate the difference in hours
       const diffHours = diffMs / (1000 * 60 * 60);
    
       // Check if the difference is at least 48 hours (2880 minutes)
       return showInfo ? diffHours >= 48 : diffHours >= 24;
        }))[0])
  }, [slotCollecte, showInfo]);
  
  const formatDateSlot = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }
  useEffect(()=> {
  
    setSlotCollecte(getUniqueDatesByDayMonthYear(slots.filter(date => {
      const now = new Date();
      const diff = (date.start.toDate() - now) / (1000 * 60 * 60);
      return diff >= 2;
  }))[0]);
  setSlotLivraison(getUniqueDatesByDayMonthYear(slots.filter(date => {
      // Convert the dates to Unix timestamps
     const filterTime = slotCollecte ? new Date(slotCollecte.start.toDate()).getTime() : new Date(premiereDate.start.toDate()).getTime();
     const dateTime = new Date(date.start.toDate()).getTime();
  
     // Calculate the difference in milliseconds
     const diffMs = dateTime - filterTime;
  
     // Calculate the difference in hours
     const diffHours = diffMs / (1000 * 60 * 60);
  
     // Check if the difference is at least 48 hours (2880 minutes)
     return showInfo ? diffHours >= 48 : diffHours >= 24;
  }))[0]);

}, []);
useEffect(() => {
  const q = query(collection(db, "slots"), orderBy("start"));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const slotsNew = [];
      querySnapshot.forEach((doc) => {
        slotsNew.push({ ...doc.data(), id: doc.id});
});
setSlots(slotsNew);
const slotInitial = slots.filter( date => {
  if(date.start.toDate() > new Date (Date.now())){
      return date;
  }
});
setSlotCollecte(slotCollecte => slotInitial[0]);
});

  return () => unsubscribe(); 
}, []);


const checkDateAndDisplayGreeting = (firestoreDate) => {
  // Convertir la date de Firestore en objet Date
  const dateFromFirestore = firestoreDate.toDate();

  // Obtenir la date actuelle
  const currentDate = new Date();




  // Comparer le jour, le mois et l'année de la date de Firestore et de la date actuelle
  const isSameOrBefore = parseInt(format(dateFromFirestore, 'yyyyMMdd')) <= parseInt(format(customerData?.slots_passages?.[0]?.toDate(), 'yyyyMMdd'));

  const isSame = parseInt(format(currentDate, 'yyyyMMdd')) === parseInt(format(customerData?.slots_passages?.[0]?.toDate(), 'yyyyMMdd'));
  const isSameCollecte = parseInt(format(currentDate, 'yyyyMMdd')) === parseInt(format(dateFromFirestore, 'yyyyMMdd'));
  // Vérifier si l'heure actuelle est inférieure à 9h30
  const before930 = currentDate.getHours() < 9 || (currentDate.getHours() === 9 && currentDate.getMinutes() < 30);

  if(isSameOrBefore){
    if(isSame && isSameCollecte){
      if(before930){
        return true
      }else{
        return false;
      }

    }else{
      return true;
    }
  }else{
    return false
  }

  // Si la date de Firestore est égale ou inférieure à la date actuelle (seulement jour, mois, année) et qu'il est moins de 9h30, afficher 'Bonjour'
  // if (isSameOrBefore && before930) {
  //   return true
  // }else{
  //   return false
  // }
}

// const isDateLessThanFiveHoursAgo = (firestoreDate)  => {
//   // Convertir la date de Firestore en objet Date
//   const dateFromFirestore = firestoreDate.toDate();

//   // Obtenir la date actuelle
//   const currentDate = new Date();

//   // Calculer la différence en minutes entre les deux dates
//   const diffInMinutes = differenceInMinutes(currentDate, dateFromFirestore);
// console.log(diffInMinutes)

//   // Comparer le jour, le mois et l'année de la date de Firestore et de la date actuelle
//   //const isSameOrBeforeA = parseInt(format(firestoreDate, 'yyyyMMdd')) <= parseInt(format(currentDate, 'yyyyMMdd'));

//   // const isSame = parseInt(format(firestoreDate, 'yyyyMMdd')) === parseInt(format(currentDate, 'yyyyMMdd'));
//   // Vérifier si la différence est supérieure ou égale à 5 heures (300 minutes)
//   if (diffInMinutes >= 300) {
//     return false
//   } else {
//     return true
//   }
// }
const isDateLessThanFiveHoursAgo = (firestoreDate) => {
  // Convertir la date de Firestore en objet Date
  const dateFromFirestore = firestoreDate.toDate();

  // Obtenir la date actuelle
  const currentDate = new Date();

  // Calculer la différence en minutes entre les deux dates
  const diffInMinutes = differenceInMinutes(dateFromFirestore, currentDate);

  // Vérifier si la différence est supérieure ou égale à 5 heures (300 minutes)
  if (diffInMinutes >= 300) {
    return true; // Date sur Firebase est inférieure d'au moins 5 heures
  } else {
    return false; // Date sur Firebase n'est pas inférieure d'au moins 5 heures
  }
}
const inputStyle = {
  border: 'none',
  borderBottom: '1px solid #008080',
  borderRadius: '0px',
};
  //----------------------------------------------------
 
  return (
    <>
                {/* Confirmer la commande */}
                <Modal 
        show={showConfirmed}
        onHide={() => setShowConfirmed(false)}
        centered
        backdrop="static"
        keyboard={false}
        className='d-flex align-items-center justify-content-center modal-customer'
      >
      <Modal.Body  className='p-3 resp' style={{maxHeight: '80vh', overflowY: 'auto' }}>
      {numberOrder ? 
      <>
      {!customerData?.homeDelivery && <p className='text-center px-1 py-3 lh-lg'>Votre commande a été enregistrée avec succès ✅. Merci de bien vouloir noter ce numéro <span className='btn mx-1 py-1' style={{background: '#008080', color: '#fff', fontWeight: '600', letterSpacing: '1.2px', cursor: 'text'}}>{orderRecap?.under_user?.[0]?.number_order}</span> sur la carte fournie et de la placer dans le sac. Nous vous remercions pour votre confiance.</p>}

      </> : 
      <>
      <p className='pt-3' style={{ fontWeight: '600' }}>Site <span className='text-secondary' style={{fontStyle: 'italic', textTransform: 'uppercase', fontWeight: '500'}}>{customerData?.nameCompany}</span></p>

      {(customerData?.underAccount || (customerData?.homeDelivery && customerData?.underAccount)) && <p style={{ fontWeight: '600' }}>Client <span className='text-secondary py-1' style={{fontStyle: 'italic', fontWeight: '500'}}>{formatStringName(orderRecap?.under_user?.[0]?.name)}</span></p>}

      <p className='pt-3' style={{ fontWeight: '600' }}>Collecte &nbsp;
        <span className='text-secondary' style={{fontStyle: 'italic', fontWeight: '500'}}>
          {(customerData?.homeDelivery && customerData?.underAccount) ? 
          <>
          {orderRecap?.date_collecte?.toDate?.() && format(orderRecap?.date_collecte?.toDate?.(), 'EEE dd MMM', { locale: fr })} | 
          {orderRecap?.date_collecte?.toDate?.() && format((orderRecap?.date_collecte?.toDate?.()), 'HH:mm', { locale: fr })} - {orderRecap?.slot_collecte_end?.toDate?.() && format((orderRecap?.slot_collecte_end?.toDate?.()), 'HH:mm', { locale: fr })}
          </> : orderRecap?.date_collecte?.toDate?.() && formatDate2(orderRecap?.date_collecte?.toDate?.())}
        </span>
      </p>
      <p style={{ fontWeight: '600' }} className='pb-3'>Livraison &nbsp;
        <span className='text-secondary' style={{fontStyle: 'italic', fontWeight: '500'}}>
          {(customerData?.homeDelivery && customerData?.underAccount) ? 
          <>
          {orderRecap?.under_user?.[0]?.date_livraison?.toDate?.() && format(orderRecap?.under_user?.[0]?.date_livraison?.toDate?.(), 'EEE dd MMM', { locale: fr })} | 
          {orderRecap?.under_user?.[0]?.date_livraison?.toDate?.() && format((orderRecap?.under_user?.[0]?.date_livraison?.toDate?.()), 'HH:mm', { locale: fr })} - {orderRecap?.under_user?.[0]?.slot_livraison_end?.toDate?.() && format((orderRecap?.under_user?.[0]?.slot_livraison_end?.toDate?.()), 'HH:mm', { locale: fr })}
          </>: orderRecap?.under_user?.[0]?.date_livraison?.toDate?.() && formatDate2(orderRecap?.under_user?.[0]?.date_livraison?.toDate?.())}
        </span>
      </p>


      {customerData?.homeDelivery && customerData?.underAccount && <p style={{ fontWeight: '600' }} className='mb-3'>Adresse <span className='text-secondary' style={{fontStyle: 'italic', fontWeight: '500'}}>{formatStringName(orderRecap?.under_user?.[0]?.address)}</span></p>}
      
      {customerData?.homeDelivery && customerData?.underAccount && <p style={{ fontWeight: '600' }} className=''>Service <span className='text-secondary' style={{fontStyle: 'italic', fontWeight: '500'}}>{formatStringName(orderRecap?.under_user?.[0]?.service)}</span></p>}

      <p className='pb-3' style={{ fontWeight: '600' }}>Prestation <span className='text-secondary' style={{fontStyle: 'italic', fontWeight: '500'}}>{orderRecap?.under_user?.[0]?.quality}</span></p>

      <p style={{ fontWeight: '600' }} className='py-2'>Récapulatif</p>

        {orderRecap?.under_user?.[0]?.recap_driver?.Pressing.length > 0 && <p style={{ fontWeight: '600', color: '#008080' }} className='my-2'>Pressing / Blanchisserie</p>}
        {orderRecap?.under_user?.[0]?.recap_driver?.Pressing?.map((item, index) => {
          return (
            <div key={index}>
              <p className='mx-2 text-secondary'>{item.total_article} x {item.article}</p>
            </div>
          )
        })}

        {orderRecap?.under_user?.[0]?.recap_driver?.Retouche.length > 0 && <p style={{ fontWeight: '600', color: '#008080' }} className='my-2'>Retouche</p>}
        {orderRecap?.under_user?.[0]?.recap_driver?.Retouche?.map((item, index) => {
          return (
            <div key={index}>
              <p className='mx-2 text-secondary'>{item.total_article} x {item.article}</p>
            </div>
          )
        })}
        {orderRecap?.under_user?.[0]?.recap_driver?.Retouche.length > 0 && 
        <>
          <Form.Group >
            <Form.Label className='m-2'>précisions concernant votre/vos retouche(s) :</Form.Label>
            <Form.Control
              as="textarea"
              placeholder='Saisissez votre demande'
              // autocomplete="off"
              // autocapitalize="none"
              value={requeteRetouche}
              onChange={handleRequeteRetouche}
              rows={2}
              className='text-start'
              style={{cursor: 'text', border: '1px solid #008080'}}
            />
          </Form.Group>
          <div className="form-check  p-0 m-0">
          <input type="checkbox" className="form-check-input mt-1" id="exampleCheck1" checked={pressingPlus} onChange={() => setPressingPlus(!pressingPlus)}/>
          <label className="form-check-label mx-2 text-secondary" for="exampleCheck1" style={{fontStyle: 'italic'}}>+ Prestation pressing <span style={{fontSize: '12px'}}>(En cochant cette case, les articles concernés par les retouches recevront également un traitement de pressing. Si vous souhaitez que cette option soit appliquée uniquement à certains articles, merci de l'indiquer dans le champ ci-dessus)</span></label>
        </div>
        </>
        }


        {orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie.length > 0 && <p style={{ fontWeight: '600', color: '#008080' }} className='my-2'>Cordonnerie</p>}
        {orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie?.map((item, index) => {
          return (
            <div key={index}>
              <p className='mx-2 text-secondary'>{item.total_article} x {item.article}</p>
            </div>
          )
        })}
        {orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie.length > 0 && 
          <Form.Group >
            <Form.Label className='m-2'>précisions concernant votre/vos cordonnerie(s) :</Form.Label>
            <Form.Control
              as="textarea"
              placeholder='Saisissez votre demande'
              value={requeteCordonnerie}
              onChange={handleRequeteCordonnerie}
              rows={2}
              className='text-start'
              style={{cursor: 'text', border: '1px solid #008080'}}
            />
          </Form.Group>
        }

        {orderRecap?.under_user?.[0]?.recap_driver?.lk.length > 0 && <p style={{ fontWeight: '600', color: '#008080' }} className='my-2'>Linge au kilo</p>}
        {orderRecap?.under_user?.[0]?.recap_driver?.lk?.map((item, index) => {
          return (
            <div key={index}>
              <p className='mx-2 text-secondary'>{item.total_article} x {item.article}</p>
            </div>
          )
        })}
      </>}
      {!numberOrder &&
      <>
      <div style={{height: '2px', background: '#F1F1F1'}} className='my-3'></div>
      <Form.Group>
            <Form.Label className='m-2 mt-0'>Notes</Form.Label>
            <Form.Control
              as="textarea"
              placeholder='Saisissez des informations complémentaires, telles que des tâches ou des précisions particulières...'
              value={requeteGlobal}
              onChange={handleRequeteGlobal}
              rows={4}
              className='text-start'
              style={{cursor: 'text', border: '1px solid #008080'}}
            />
          </Form.Group>
      </>}
     
        </Modal.Body>
        <Modal.Footer >
        {!numberOrder && orderRecap?.under_user?.[0]?.recap_driver?.Retouche.length > 0 && orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie.length === 0 && <p className='text-secondary text-start w-100 mb-2' style={{fontStyle: 'italic', fontSize: '0.9rem'}}>⚠️ À noter : Le délai pour les retouches est généralement d'une semaine.</p>}
        {!numberOrder && orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie.length > 0 && orderRecap?.under_user?.[0]?.recap_driver?.Retouche.length === 0 && <p className='text-secondary text-start w-100 mb-2' style={{fontStyle: 'italic', fontSize: '0.9rem'}}>⚠️ À noter : Le délai pour la cordonnerie est généralement d'une semaine.</p>}
        {!numberOrder && orderRecap?.under_user?.[0]?.recap_driver?.Retouche.length > 0 && orderRecap?.under_user?.[0]?.recap_driver?.Cordonnerie.length > 0  && <p className='text-secondary text-start w-100 mb-2' style={{fontStyle: 'italic', fontSize: '0.9rem'}}>⚠️ À noter : Le délai pour les retouches et la cordonnerie est généralement d'une semaine.</p>}
        {numberOrder ? !customerData?.homeDelivery &&
          <button className='btn btn-light' style={{border: '1px solid #008080', color: '#008080'}} onClick={() => {setShowConfirmed(false); setNumberOrder(false)}}>
            Fermer
          </button>
       : 
        <>
        <button className='btn btn-outline-info btn_edit' onClick={() => {setShowConfirmed(false); setRequeteCordonnerie(''); setRequeteRetouche(''); setPressingPlus(false); setRequeteGlobal('')}}>
            Modifier
          </button>
          <button className='btn' style={{background: '#009090', color: '#fff'}} onClick={showSelectedOptions}>Valider</button>
        </>}
        </Modal.Footer>
      </Modal>
              <Modal
              style={{zIndex: '9999999'}}
              show={modalShowCollecte}
              onHide={() => setModalShowCollecte(false)}
              size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
            >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              <p style={{fontSize: '1rem'}} className='text-start text-secondary'>Choisissez un créneau de collecte</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
              <div className='container'>
                <div className='row'>
                    <div className='col-8 rounded' style={{maxHeight: '200px', overflowY: 'scroll'}}>
                    {uniqueDates.map((doc, index) => {
                        return(
                            <p className='rounded p-1' onClick={() => {setIndexClick(index); setIndexClickHH(0); setSlotCollecte(doc); setIndexClickLivraison(0); setIndexClickHHLivraison(0); setSlotLivraison(slotLivraison => premiereDateLivraison)}} style={indexClick === index ? {cursor: 'pointer', background: '#008080', color: '#fff', fontSize: '0.9rem'} : {cursor: 'pointer', fontSize: '0.9rem'}}>{formatDateSlot(doc.start.toDate().toISOString())}</p>
                        )  
                    })
                    }
                    </div>
                    <div className='col-4' style={{maxHeight: '200px', overflowY: 'scroll'}}>
                    {matchingDates.map((doc, index) => {
                                return (
                            <p className='rounded p-1' onClick={() => {setIndexClickHH(index); setSlotCollecte(doc)}} style={indexClickHH === index ? {cursor: 'pointer', background: '#008080', color: '#fff', fontSize: '0.9rem'} : {cursor: 'pointer', fontSize: '0.9rem'}}>{format((doc.start.toDate()), 'HH:mm', { locale: fr })} - {format((doc.end.toDate()), 'HH:mm', { locale: fr })}</p>
                        )
                    })}
                    </div>
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => setModalShowCollecte(false)} className='btn bttn text-light' style={{background: '#008080', letterSpacing: '1.1px'}}>Confirmer</button>
              </Modal.Footer>
          </Modal>

          <Modal
              style={{zIndex: '9999999'}}
              show={modalShowLivraison}
              onHide={() => setModalShowLivraison(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className='mx-auto'
            >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <p style={{fontSize: '1rem'}} className='text-start text-secondary'>Choisissez un créneau de livraison</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='container'>
                <div className='row'>
                    <div className='col-8 rounded' style={{maxHeight: '200px', overflowY: 'scroll'}}>
                    {uniqueDatesLivraison.map((doc, index) => {
                        return(
                            <p className='rounded p-1' onClick={() => {setIndexClickLivraison(index); setIndexClickHHLivraison(0); setSlotLivraison(doc)}} style={indexClickLivraison === index ? {cursor: 'pointer', background: '#008080', color: '#fff', fontSize: '0.9rem'} : {cursor: 'pointer', fontSize: '0.9rem'}}>{formatDateSlot(doc.start.toDate().toISOString())}</p>
                        )  
                    })
                    }
                    </div>
                    <div className='col-4' style={{maxHeight: '200px', overflowY: 'scroll'}}>
                    {matchingDatesLivraison.map((doc, index) => {
                                return (
                            <p className='rounded p-1' onClick={() => {setIndexClickHHLivraison(index); setSlotLivraison(doc)}} style={indexClickHHLivraison === index ? {cursor: 'pointer', background: '#008080', color: '#fff', fontSize: '0.9rem'} : {cursor: 'pointer', fontSize: '0.9rem'}}>{format((doc.start.toDate()), 'HH:mm', { locale: fr })} - {format((doc.end.toDate()), 'HH:mm', { locale: fr })}</p>
                        )
                    })}
                    </div>
                </div>
              </div>
              </Modal.Body>
              <Modal.Footer>
                <button className='btn bttn text-light' style={{background: '#008080', letterSpacing: '1.1px'}} onClick={() => setModalShowLivraison(false)}>Confirmer</button>
              </Modal.Footer>
          </Modal>
      {/* Afficher detail */}
      <Modal show={show} fullscreen={fullscreen} onHide={() => hind()} >
        <Modal.Header closeButton className='p-3'>
          <Modal.Title >Récapulatif de la commande</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='text-center'>
            {/* <p className='p-2 p-md-5 pt-3 pb-4 text-start' style={{ fontWeight: '700' }}>Numéro de commande <span style={{ color: '#3BACB6' }}>{order.number_order}</span></p> */}
            <p className='text-start p-3'>Liste des commandes</p>
            <div className='row'>
                <div className='col-md-6 pb-5' style={{ borderRight: '1px solid #EFEFEF', height: '80vh', borderBottom: '1px solid #EFEFEF', overflowY:'auto' }}>
                    {order.under_user && order?.under_user?.map((item, index) => ({ ...item, initialIndex: index })).filter((item) => item.date_creation !== undefined).sort((a, b) => {
                      return b.date_creation.toMillis() - a.date_creation.toMillis();}).map((doc, index) => {
                        return (
                            <div key={index} className='row pb-3 border-bottom mx-2 justify-content-between' onClick={() => handleRecap(doc.initialIndex)} style={{ cursor: 'pointer' }}>
                            <p className='col align-baseline pt-3 m-0 p-0 text-start mx-3 text-uppercase'>
                            {(customerData?.underAccount || customerData?.homeDelivery) && 
                              <span  style={{fontWeight: '600'}}>{doc.name}</span>
                            }
                            {(!customerData?.underAccount && !customerData?.homeDelivery) && 
                              <span  style={{fontWeight: '600'}}>{doc.number_order}</span>
                            }

                            {
                              order.etat_commande === 1 && (
                              customerData?.homeDelivery ?
                              (
                              isDateLessThanFiveHoursAgo(order.date_collecte) && <FaTrash className='text-danger mx-2 mb-1' onClick={() => setConfirmedDelete(confirmedDelete => doc.number_order)}/>
                              ): 
                              (checkDateAndDisplayGreeting(order.date_collecte) && <FaTrash className='text-danger mx-2 mb-1' onClick={() => setConfirmedDelete(confirmedDelete => doc.number_order)}/>
                              )
                              )

                            }
                        


                            {/* {order.etat_commande === 1 && <FaTrash className='text-danger mx-2 mb-1' onClick={() => setConfirmedDelete(confirmedDelete => doc.number_order)}/>}  */}
                            {confirmedDelete === doc.number_order && <>
                            <span className='text-secondary' style={{fontStyle: 'italic', fontSize: '12px'}}>Voullez-vous annuler cette commande ? </span>
                            <span className='text-secondary' style={{textDecoration: 'underline', fontStyle: 'italic', fontSize: '12px', fontWeight: '600'}} onClick={() => deleteOrder(doc.initialIndex)}>Oui. </span> 
                            <span className='btn-link mx-1' style={{fontStyle: 'italic', fontSize: '12px', fontWeight: '600'}} onClick={() => setConfirmedDelete(confirmedDelete => '')}>NON.</span></>}




                          </p>
                            <p className='col align-baseline pt-3 m-0 p-0 text-end mx-3' style={{color: '#008080', fontStyle: 'italic'}}>{(customerData.underAccount || customerData?.homeDelivery) && doc.number_order}<AiOutlineArrowRight color='#008080' className='mx-2'/></p>
                            </div>

                      )
                    })}
                </div>
                <div className='col-md-6' style={{ borderBottom: '1px solid #EFEFEF', height: '80vh', overflowY: 'auto' }}>

                <div className='mx-auto px-4 py-3 my-2 mb-3' style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}>
                  <div className="row justify-content-between">
                    <div className='col-sm-12 col-md-6 align-self- text-start'>
                      <p style={{ fontWeight: '600' }}>Client : <span style={{ fontWeight: '500', textTransform : 'uppercase' }}>{(customerData?.underAccount || customerData?.homeDelivery) ? order?.under_user?.[underCustomer]?.name : customerData?.nameCompany}</span></p>
                      <p style={{ fontWeight: '600' }} className= 'py-1'>N° de commande : <span style={{ fontWeight: '500' }}>{order?.under_user?.[underCustomer]?.number_order}</span></p>
                      <p style={{ fontWeight: '600' }}>Prestation : <span style={{ fontWeight: '500' }}>{order?.under_user?.[underCustomer]?.quality}</span></p>
                      {customerData?.homeDelivery && <p className= 'py-1' style={{ fontWeight: '600' }}>Service : <span style={{ fontWeight: '500' }}>{order?.under_user?.[underCustomer]?.service}</span></p>}
                    </div>
                    <div className='col-sm-12 col-md-6 align-self-center text-start'>
                    <p style={{ fontWeight: '600' }}>Collecte : <span style={{ fontWeight: '500' }}>
                    {customerData?.homeDelivery ? 
                    (order?.date_collecte && order?.slot_collecte_end ?
                        `${format(order?.date_collecte?.toDate(), 'EEE dd MMM', { locale: fr })} | ${format((order?.date_collecte?.toDate()), 'HH:mm', { locale: fr })} - ${format((order?.slot_collecte_end?.toDate()), 'HH:mm', { locale: fr })}` : '-'
                    ): 
                    (order?.date_collecte ?
                      format(order?.date_collecte?.toDate(), 'EEE dd MMM', { locale: fr }) : '-'
                    )
                    }</span></p>
                    <p style={{ fontWeight: '600' }}>Livraison : <span style={{ fontWeight: '500' }}>
                    {customerData?.homeDelivery ?
                      (order?.under_user?.[underCustomer]?.date_livraison && order?.under_user?.[underCustomer]?.slot_livraison_end ?
                        `${format(order?.under_user?.[underCustomer]?.date_livraison?.toDate(), 'EEE dd MMM', { locale: fr })} | ${format((order?.under_user?.[underCustomer]?.date_livraison?.toDate()), 'HH:mm', { locale: fr })} - ${format((order?.under_user?.[underCustomer]?.slot_livraison_end?.toDate()), 'HH:mm', { locale: fr })}` : '-'
                      )
                      :
                      (order?.under_user?.[underCustomer]?.date_livraison ?
                      format(order?.under_user?.[underCustomer]?.date_livraison?.toDate(), 'EEE dd MMM', { locale: fr }) : '-'
                    )
                    }</span></p>
                    {customerData?.homeDelivery && <p style={{ fontWeight: '600' }}>Adresse : <span style={{ fontWeight: '500' }}>{order?.under_user?.[underCustomer]?.address}</span></p>}
                      <p style={{ fontWeight: '600' }}>État : <span style={{ fontWeight: '500' }}>{order?.under_user?.[underCustomer]?.etat_second === '' ? getStatusOrder(order?.etat_commande) : getStatusOrder(parseInt(order?.under_user?.[underCustomer]?.etat_second))}</span></p>
                    </div>
                  </div>
                </div>

                {(order.etat_commande === 1 || order.etat_commande === 2 || order.etat_commande === 3 ) &&
                <>
                <table className="table mx-auto" style={{ width: '90%', color: '#008080' }} >
                      <thead>
                        <tr>
                          <th scope="col" className='col-md-auto p-2 px-0 text-start'>Description</th>
                          <th scope="col" className='col-md-auto p-2'>Quantité</th>
                        </tr>
                      </thead>
                      <tbody>
                      {/* Pressing */}
                      {order?.under_user[underCustomer]?.recap_driver?.Pressing.length > 0 &&
                          <tr>
                            <td colSpan="4" style={{ border: 'none' }}>
                              <p className='mb-2 mt-3 h6 text-start' style={{ fontWeight: '600', color: 'black' }}>Pressing</p>
                            </td>
                          </tr>
                        }
                        {order?.under_user[underCustomer]?.recap_driver?.Pressing?.length > 0 && order?.under_user[underCustomer]?.recap_driver?.Pressing?.map((doc, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ color: '#8f8f8f', border: 'none' }} className='text-start'>{doc.article}</td>
                              <td style={{ color: '#8f8f8f', border: 'none' }} className='text-center'>{doc.total_article}</td>
                            </tr>
                          )
                        })
                        }
                        {order?.under_user[underCustomer]?.recap_driver?.Pressing?.length > 0 &&
                          <tr>
                            <td colSpan="4" style={{ border: 'none' }}>
                            <div className='mx-auto mt-3' style={{ height: '1px', width: '100%', background: '#F1F1F1' }}></div>
                            </td>
                          </tr>
                        }
                       {/* Retouche */}
                        {order?.under_user[underCustomer]?.recap_driver?.Retouche?.length > 0 &&
                          <tr>
                            <td colSpan="4" style={{ border: 'none' }}>
                              <p className='mb-2 mt-3 h6 text-start' style={{ fontWeight: '600', color: 'black' }}>Retouches</p>
                            </td>
                          </tr>
                        }
                        {order?.under_user[underCustomer]?.recap_driver?.Retouche?.length > 0 && order?.under_user[underCustomer]?.recap_driver?.Retouche?.map((doc, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ color: '#8f8f8f', border: 'none' }} className='text-start'>{doc.article}</td>
                              <td style={{ color: '#8f8f8f', border: 'none' }} className='text-center'>{doc.total_article}</td>
                            </tr>
                          )
                        })
                        }
                        {order?.under_user[underCustomer]?.recap_driver?.Retouche?.length > 0 &&
                          <tr>
                            <td colSpan="4" style={{ border: 'none' }}>
                            <div className='mx-auto mt-3' style={{ height: '1px', width: '100%', background: '#F1F1F1' }}></div>
                            </td>
                          </tr>
                        }
                        {/* Cordonnerie */}
                        {order?.under_user[underCustomer]?.recap_driver?.Cordonnerie?.length > 0 &&
                          <tr>
                            <td colSpan="4" style={{ border: 'none' }}>
                              <p className='mb-2 mt-3 h6 text-start' style={{ fontWeight: '600', color: 'black' }}>Cordonnerie</p>
                            </td>
                          </tr>
                        }
                        {order?.under_user[underCustomer]?.recap_driver?.Cordonnerie?.length > 0 && order?.under_user[underCustomer]?.recap_driver?.Cordonnerie?.map((doc, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ color: '#8f8f8f', border: 'none' }} className='text-start'>{doc.article}</td>
                              <td style={{ color: '#8f8f8f', border: 'none' }} className='text-center'>{doc.total_article}</td>
                            </tr>
                          )
                        })
                        }
                        {order?.under_user[underCustomer]?.recap_driver?.Cordonnerie?.length > 0 &&
                          <tr>
                            <td colSpan="4" style={{ border: 'none' }}>
                              <div className='mx-auto mt-3' style={{ height: '1px', width: '100%', background: '#F1F1F1' }}></div>
                            </td>
                          </tr>
                        }
                        {/* lk */}
                        {order?.under_user[underCustomer]?.recap_driver?.lk?.length > 0 &&
                          <tr>
                            <td colSpan="4" style={{ border: 'none' }}>
                              <p className='mb-2 mt-3 h6 text-start' style={{ fontWeight: '600', color: 'black' }}>Linge au kilo</p>
                            </td>
                          </tr>
                        }
                        {order?.under_user[underCustomer]?.recap_driver?.lk?.length > 0 && order?.under_user[underCustomer]?.recap_driver?.lk?.map((doc, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ color: '#8f8f8f', border: 'none' }} className='text-start'><div>{doc.article}</div></td>
                              <td style={{ color: '#8f8f8f', border: 'none' }} className='text-center'>{doc.total_article}</td>
                            </tr>
                          )
                        })
                        }
                        {order?.under_user[underCustomer]?.recap_driver?.lk?.length > 0 &&
                          <tr>
                            <td colSpan="4" style={{ border: 'none' }}>
                              <div className='mx-auto mt-3' style={{ height: '1px', width: '100%', background: '#F1F1F1' }}></div>
                            </td>
                          </tr>
                        }

                      </tbody>
                    </table>
                </>
                }


                {(order.etat_commande === 4 || order.etat_commande === 5 ) &&
                <>


                <table className="table mx-auto" style={{ width: '90%', color: '#008080' }} >
                <thead>
                  <tr>
                    <th scope="col" className='col-md-auto p-2 px-0 text-start'>Déscription</th>
                    <th scope="col" className='col-md-auto p-2'>Quantité</th>
                    <th scope="col" className='col-md-auto p-2'>Prix</th>
                    <th scope="col" className='col-md-auto p-2 text-center'>Total</th>
                  </tr>
                </thead>
                <tbody >
                  {/* Pressing */}
                  {order?.under_user[underCustomer]?.recap_presta?.Pressing?.length > 0 &&
                    <tr>
                      <td colSpan="4" style={{ border: 'none' }}>
                        <p className='mb-2 mt-3 h6 text-start' style={{ fontWeight: '600', color: 'black' }}>Pressing</p>
                       </td>
                    </tr>
                  }
                  {order?.under_user[underCustomer]?.recap_presta?.Pressing?.length > 0 && order?.under_user[underCustomer]?.recap_presta?.Pressing?.map((doc, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-start'>{doc.article}</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-center'>{doc.total_article}</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-end'>{doc.price.toFixed(2)} €</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-end'>{doc.total.toFixed(2)} €</td>
                      </tr>
                    )
                  })
                  }
                  {order?.under_user[underCustomer]?.recap_presta?.Pressing?.length > 0 &&
                    <tr>
                      <td colSpan="4" style={{ border: 'none' }}>
                      <div className='mx-auto mt-3' style={{ height: '1px', width: '100%', background: '#F1F1F1' }}></div>
                       </td>
                    </tr>
                  }
                  {/* Retouche */}
                  {order?.under_user[underCustomer]?.recap_presta?.Retouche?.length > 0 &&
                    <tr>
                      <td colSpan="4" style={{ border: 'none' }}>
                        <p className='mb-2 mt-3 h6 text-start' style={{ fontWeight: '600', color: 'black' }}>Retouche</p>
                      </td>
                    </tr>
                  }
                  {order?.under_user[underCustomer]?.recap_presta?.Retouche?.length > 0 && order?.under_user[underCustomer]?.recap_presta?.Retouche?.map((doc, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-start'>{doc.article}</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-center'>{doc.total_article}</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-end'>{doc.price.toFixed(2)} €</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-end'>{doc.total.toFixed(2)} €</td>
                      </tr>
                    )
                  })
                  }
                  {order?.under_user[underCustomer]?.recap_presta?.Retouche?.length > 0 &&
                    <tr>
                      <td colSpan="4" style={{ border: 'none' }}>
                      <div className='mx-auto mt-3' style={{ height: '1px', width: '100%', background: '#F1F1F1' }}></div>
                      </td>
                    </tr>
                  }
                  {/* Cordonnerie */}
                  {order?.under_user[underCustomer]?.recap_presta?.Cordonnerie?.length > 0 &&
                    <tr>
                      <td colSpan="4" style={{ border: 'none' }}>
                        <p className='mb-2 mt-3 h6 text-start' style={{ fontWeight: '600', color: 'black' }}>Cordonnerie</p>
                      </td>
                    </tr>
                  }
                  {order?.under_user[underCustomer]?.recap_presta?.Cordonnerie?.length > 0 && order?.under_user[underCustomer]?.recap_presta?.Cordonnerie?.map((doc, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-start'>{doc.article}</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }}>{doc.total_article}</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }}>{doc.price.toFixed(2)} €</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }}>{doc.total.toFixed(2)} €</td>
                      </tr>
                    )
                  })
                  }
                  {order?.under_user[underCustomer]?.recap_presta?.Cordonnerie?.length > 0 &&
                    <tr>
                      <td colSpan="4" style={{ border: 'none' }}>
                        <div className='mx-auto mt-3' style={{ height: '1px', width: '100%', background: '#F1F1F1' }}></div>
                      </td>
                    </tr>
                  }
                {/* Lk */}
                {order?.under_user[underCustomer]?.recap_presta?.lk?.length > 0 &&
                    <tr>
                      <td colSpan="4" style={{ border: 'none' }}>
                        <p className='mb-2 mt-3 h6 text-start' style={{ fontWeight: '600', color: 'black' }}>Linge au kilo</p>
                       </td>
                    </tr>
                  }
                  {order?.under_user[underCustomer]?.recap_presta?.lk?.length > 0 && order?.under_user[underCustomer]?.recap_presta?.lk?.map((doc, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-start'><div>{doc.article}</div></td>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-center'>{doc.total_article}</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-end'>{doc.price.toFixed(2)} €</td>
                        <td style={{ color: '#8f8f8f', border: 'none' }} className='text-end'>{doc.total.toFixed(2)} €</td>
                      </tr>
                    )
                  })
                  }
                  {order?.under_user[underCustomer]?.recap_presta?.lk?.length > 0 &&
                    <tr>
                      <td colSpan="4" style={{ border: 'none' }}>
                        <div className='mx-auto mt-3' style={{ height: '1px', width: '100%', background: '#F1F1F1' }}></div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>

              <div className="row justify-content-end mt-4 mb-3 mx-3 mx-md-5 px-md-3">
                <div className="col-auto text-start mx-3">
                  <p style={{fontWeight: '600'}}>Total HT </p>
                  <p style={{fontWeight: '600'}}>TVA (20%) </p>
                  <p style={{fontWeight: '600'}}>Total TTC </p>
                </div>
                <div className="col-auto text-end">
                  <p>{Number.parseFloat(order?.under_user[underCustomer]?.total/1.2).toFixed(2)} €</p>
                  <p>{(Number.parseFloat(order?.under_user[underCustomer]?.total).toFixed(2) - Number.parseFloat(order?.under_user[underCustomer]?.total/1.2).toFixed(2)).toFixed(2)} €</p>
                  <p>{Number.parseFloat(order?.under_user[underCustomer]?.total).toFixed(2)} €</p>
                </div>
              </div>
                </>
                  }
              <div>
              {order?.under_user && order?.notes?.filter(note => note.ref === order?.under_user?.[underCustomer]?.number_order).length > 0 &&
              <div style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px', fontWeight: '600' }} className='text-start p-2 mx-auto'>
              <p className='pb-2'>Note(s) :</p>
                {order?.notes?.filter(note => note.ref === order?.under_user?.[underCustomer]?.number_order && note.public).map((noteDetails, index) => {
                return (
                  <div key={index}>
                  <p>{noteDetails.title} : <span style={{ fontWeight: '500', fontStyle: 'italic' }} className='text-secondary'>{noteDetails.comment}</span></p>
                  {noteDetails.pressing && index === order?.notes?.filter(note => note.ref === order?.under_user?.[underCustomer]?.number_order && note.public).length -1 && (order.etat_commande === 1 || order.etat_commande === 2 ) && <p className='text-secondary' style={{ fontWeight: '400', fontStyle: 'italic' }}>Les articles nécessitant des retouches bénéficieront également d'un traitement de pressing.</p>}
                  </div>
                )})}
              </div>}
              {order?.under_user && <p className='text-center pt-3'>Votre commande vous {order?.under_user?.[underCustomer]?.etat_second === ''?
              order.etat_commande === 5 ? 
                'a bien été' : 'sera' 
                : parseInt(order?.under_user[underCustomer]?.etat_second) === 5 ? 'a bien été' : 'sera'}  restituée le {formatDate2(order?.under_user[underCustomer]?.date_livraison?.toDate()?.toISOString())} entre {customerData?.homeDelivery ?
                      (order?.under_user?.[underCustomer]?.date_livraison && order?.under_user?.[underCustomer]?.slot_livraison_end ?
                        `${format((order?.under_user?.[underCustomer]?.date_livraison?.toDate()), 'HH:mm', { locale: fr })} - ${format((order?.under_user?.[underCustomer]?.slot_livraison_end?.toDate()), 'HH:mm', { locale: fr })}` : '-'
                      )
                      :
                      `${customerData.slot_start} - ${customerData.slot_end}`
                    }
                    .</p>}
                <p className='p-3'>Si vous avez des questions, n'hésitez pas à contacter notre service client au : <a style={{ textDecoration: 'none', outline: 'none', color: '#008080' }} href="tel:+33184805008">01 84 80 50 08</a> ou sur <a href="mailto:corporate@cleamy.com" style={{ textDecoration: 'none', outline: 'none', color: '#008080' }}>corporate@cleamy.com</a> .</p>
              </div>
                </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Ajouter une commande */}
      <Modal 
        show={showAddOrder}
        fullscreen={fullscreen}
        onHide={onHideAddOrder}
      >
      <Modal.Header closeButton className='p-3'>
        <Modal.Title className='mx-auto'>
          <FaPlus
            size={12}
            color='#008080'
          />
          <span style={{ fontSize: '1.2rem', color: '#008080' }}> Ajouter une commande</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  className='p-3'>

        <p className='py-3' style={{ fontWeight: '600' }}>Site <span className='text-secondary' style={{fontStyle: 'italic', textTransform: 'uppercase'}}>{customerData?.nameCompany}</span></p>
        
        
        {(customerData?.underAccount || customerData?.homeDelivery) &&
          <div className="d-flex align-items-start align-items-md-center flex-md-row flex-column mb-3">
          <p className='py-3' style={{ fontWeight: '600' }}>Selectionner un compte </p>
          <DropdownButton
            title={selectedUnderAccount ? `${selectedUnderAccount} ${showPhoneSelected ? `| ${showPhoneSelected}` : ''}` : 'Sélectionner un compte'}
            onSelect={handleSelect}
            id='dropdown-button-drop-down-centered'
            drop='down-centered'
            className='custom-dropdown mx-md-3'
          >
          <Dropdown.Item onClick={() => {setShowAddUnderAccount(true); setShowAddAdress(false);setSelectedAddress(null); setSearchAdress(''); setCmpAddres('')}} eventKey={selectedUnderAccount}>
            <FaPlus size={12} color='#008080'/><span className='mx-1' style={{color: '#008080'}}>Ajouter un compte</span>
          </Dropdown.Item>
          <Dropdown.Divider />
            {underAccountData?.map((item, index) => (
                <Dropdown.Item key={index} eventKey={`${item.first_name} ${item.last_name}`} onClick={() => {setShowAddUnderAccount(false); setSelectedUnderUser(item); setSelectedAddress(null); setIndexCustomer(index); setSearchAdress(''); setShowPhoneSelected(item.tel)}}>
                  {`${item.first_name} ${item.last_name}`}
                </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        }
        {(customerData?.underAccount || customerData?.homeDelivery) && showAddUnderAccount && <>
          <div className="container">
            <form onSubmit={handleAddCustomer}>
              <div className="d-flex flex-md-row flex-column">
                <div className=" flex-fill">
                  <FloatingLabel controlId="floatingLastName" label="Nom *">
                    <Form.Control 
                      type="text"
                      style={{border: 'none', borderBottom: '1px solid #008080', borderRadius: '0px'}}
                      onChange={(e) => setLastNameUnderUser(e.target.value)}
                      value={lastNameUnderUser}
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="px-md-3 py-2 py-md-0 flex-fill">
                  <FloatingLabel controlId="floatingFirstName" label="Prénom *">
                    <Form.Control 
                      type="text"
                      style={{border: 'none', borderBottom: '1px solid #008080', borderRadius: '0px'}}
                      onChange={(e) => setFirstNameUnderUser(e.target.value)}
                      value={firstNameUnderUser}
                      required
                    />
                  </FloatingLabel>
                </div>
                <div className="pe-2 flex-fill">
                <PhoneInput
                  inputStyle={inputStyle}
                  inputClassName="phone-form-input"
                  name="phone Number"
                  country="fr"
                  value={firstPhone}
                  onChange={(value) => setFirstPhone(`+${value}`)}
                />
                </div>
                <div className="p-0 flex-fill">
                  <FloatingLabel controlId="floatingEmail" label="Email">
                    <Form.Control 
                      type="email"
                      style={{border: 'none', borderBottom: '1px solid #008080', borderRadius: '0px'}}
                      onChange={(e) => setEmailUnderUser(e.target.value)}
                      value={emailUnderUser}
                    />
                  </FloatingLabel>
                </div>
                <div class="p-3 flex-fill mx-auto">
                  <button 
                    onClick={() => {setShowAddUnderAccount(false); setShowAddAdress(false); setLastNameUnderUser(''); setFirstNameUnderUser(''); setFirstPhone(''); setEmailUnderUser('')}}
                    className='btn btn-outline-danger mx-1 my-2'
                  >
                    Annuler
                  </button>
                  <button type='submit' className='mx-1 btn btn-outline-success'>Confirmer</button>
                </div>
              </div>
            </form>
          </div>
        </>}
        {selectedUnderAccount && customerData?.homeDelivery && <>
          <div className="container m-0 p-0">

            <form onSubmit={handleAddAdressCustomer}>
              <div className="d-flex flex-md-row flex-column">
              <div className=" flex-fill">

          <div className="d-flex align-items-start align-items-md-center flex-md-row flex-column  mb-3">
          <p className='py-3' style={{ fontWeight: '600' }}>Selectionner une adresse </p>
          <DropdownButton
            title={selectedAddress ? `${selectedAddress} | ${cmpAdress}` : 'Sélectionner une adresse'}

            onSelect={handleSelectAddress}

            id='dropdown-button-drop-down-centered'
            drop='down-centered'
            className='custom-dropdown mx-md-3'
          >
          <Dropdown.Item onClick={() => {setShowAddAdress(true); setShowAddUnderAccount(false)}} eventKey={selectedAddress}>
            <FaPlus size={12} color='#008080'/><span className='mx-1' style={{color: '#008080'}}>Ajouter une adresse</span>
          </Dropdown.Item>
          <Dropdown.Divider />

            {underAccountData?.[indexCustomer]?.addresses && underAccountData?.[indexCustomer]?.addresses?.map((item, index) => (
                <Dropdown.Item key={index} eventKey={item.adresse} onClick={() => {setSelectedAddress(item); setShowAddAdress(false); setSearchAdress(''); setCmpAddres(''); setCmpAdress(item.complment)}}>
                {item.adresse} | {item.complment}
                </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        
              </div>
              {showAddAddress && <>
                <div className=" flex-fill">

                </div>
                <div className=" flex-fill">
                  <Address setSearchAdress={setSearchAdress} searchAdress={searchAdress}/>
                </div>
                <div className="px-md-3 py-2 py-md-0 flex-fill">
                  <FloatingLabel controlId="floatingFirstName" label="Complément d'adresse">
                    <Form.Control 
                      type="text"
                      style={{border: 'none', borderBottom: '1px solid #008080', borderRadius: '0px'}}
                      onChange={(e) => setCmpAddres(e.target.value)}
                      value={cmpAddress}
                    />
                  </FloatingLabel>
                </div>
                <div class="p-3 flex-fill mx-auto">
                  <button 
                    onClick={() => {setShowAddUnderAccount(false); setShowAddAdress(false); setLastNameUnderUser(''); setFirstNameUnderUser(''); setFirstPhone(''); setEmailUnderUser(''); setSearchAdress(''); setCmpAddres('')}}
                    className='btn btn-outline-danger mx-1 my-2'
                  >
                    Annuler
                  </button>
                  <button type='submit' className='mx-1 btn btn-outline-success'>Confirmer</button>
                </div>
              </>}

              </div>
            </form>
          </div>
        </>}

        {customerData?.homeDelivery && <>
          <div >
          <p className='py-0 mb-3' style={{ fontWeight: '600' }}>Service</p>
          <div className='rounded py-2 px-3 mx-md-5' style={{background: '#F5F5F5'}}>
                <div className='my-2 mx-0'>
                  <div className='row align-items-center justify-content-center mx-auto'>
                    <div className='col-6'>
                      <div className="d-grid gap-2">
                        <button className="btn p-1 px-3 py-2 bttn col-md-6 mx-md-auto" type="button" style={showInfo ? {background: '#008080', color: 'white', borderRadius: '99px', fontSize: '0.8rem', fontWeight: '600'} : {background: 'transparent', color: '#008080', border: 'none', borderRadius: '99px', fontSize: '0.8rem', fontWeight: '600'}} onClick={initialiseService}>Standard</button>
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className="d-grid gap-2">
                        <button className="btn p-1 py-2 bttn col-md-6 mx-md-auto" type="button" style={showInfo ? {background: 'transparent', color: '#008080', border: 'none', borderRadius: '99px', fontSize: '0.8rem', fontWeight: '600'} : {background: '#D4A03C', color: 'white', borderRadius: '99px', fontSize: '0.8rem', fontWeight: '600'}} onClick={initialiseService2}>Prime</button>
                      </div>
                    </div>
                  </div>
                  {showInfo ? 
                  <div>
                    <div className='d-flex mt-4 mx-2 align-items-center justify-content-start'>
                      <div className='align-self-center px-2'><MdTaskAlt color='#008080' size={18}/></div>
                      <div className='align-self-center text-secondary' style={{fontSize: '12px'}}>Créneaux horaires de 30 minutes</div>
                    </div>
                    <div className='d-flex mt-1 mx-2 align-items-center justify-content-start'>
                      <div className='align-self-center px-2'><MdTaskAlt color='#008080' size={18}/></div>
                      <div className='align-self-center text-secondary' style={{fontSize: '12px'}}>Délai de traitement de 48h</div>
                    </div>
                    <div className='d-flex mt-1 mb-3 mx-2 align-items-center justify-content-start'>
                      <div className='align-self-center px-2'><MdTaskAlt color='#008080' size={18}/></div>
                      <div className='align-self- text-secondary' style={{fontSize: '12px'}}>Frais de livraison offerts</div>
                    </div>
                  </div> 
                  :
                  <div>
                    <div className='d-flex mt-4 mx-2 align-items-center justify-content-start'>
                      <div className='align-self-center px-2'><MdOutlineAddTask color='#D4A03C' size={18}/></div>
                      <div className='align-self-center text-secondary' style={{fontSize: '12px'}}>Créneaux horaires de 30 minutes</div>
                    </div>
                    <div className='d-flex mt-1 mx-2 align-items-center justify-content-start'>
                      <div className='align-self-center px-2'><MdOutlineAddTask color='#D4A03C' size={18}/></div>
                      <div className='align-self-center text-secondary' style={{fontSize: '12px'}}>Délai de traitement de 24h</div>
                    </div>
                    <div className='d-flex mt-1 mb-3 mx-2 align-items-center justify-content-start'>
                      <div className='align-self-center px-2'><MdOutlineAddTask color='#D4A03C' size={18}/></div>
                      <div className='align-self- text-secondary' style={{fontSize: '12px'}}>Frais de livraison de 9,99 €</div>
                    </div>
                  </div>
                  }
                </div>
          </div>
          <p className='mb-2 mt-3' style={{ fontWeight: '600' }}>Dates & Horaires</p>
          <div className='rounded py-2 px-3 mx-md-5' style={{background: '#F5F5F5'}}>
                <div className='my-2 mx-0'>
                  <div className='row text-center'>
                    <div className='col-6' style={{borderRight: '1px solid #DCDCDC', cursor: 'pointer'}} onClick={() => setModalShowCollecte(true)}>
                        <p>Collecte</p>
                        {slotCollecte ? <p className='py-1' style={{fontWeight: '500', fontSize: '0.8rem', color: "#008080"}}>{format(slotCollecte.start.toDate(), 'EEE dd MMM', { locale: fr })}</p> : <p className='py-1' style={{fontWeight: '500', fontSize: '0.8rem', color: "#008080"}}>{premiereDate && format(premiereDate.start.toDate(), 'EEE dd MMM', { locale: fr })}</p>}
                        {slotCollecte ? <p style={{fontWeight: '500', fontSize: '0.9rem'}}>{format((slotCollecte.start.toDate()), 'HH:mm', { locale: fr })} - {format((slotCollecte.end.toDate()), 'HH:mm', { locale: fr })}</p> : <p style={{fontWeight: '500', fontSize: '0.9rem'}}>{premiereDate && format((premiereDate.start.toDate()), 'HH:mm', { locale: fr })} - {premiereDate && format((premiereDate.end.toDate()), 'HH:mm', { locale: fr })}</p>}
                    </div>
                    <div className='col-6' style={{cursor: 'pointer'}} onClick={() => setModalShowLivraison(true)}>
                        <p>Livraison</p>
                        {slotLivraison ? <p className='py-1' style={{fontWeight: '500', fontSize: '0.8rem', color: "#008080"}}>{format(slotLivraison.start.toDate(), 'EEE dd MMM', { locale: fr })}</p> : <p className='py-1' style={{fontWeight: '500', fontSize: '0.8rem', color: "#008080"}}>{premiereDateLivraison && format(premiereDateLivraison.start.toDate(), 'EEE dd MMM', { locale: fr })}</p>}
                        {slotLivraison ? <p style={{fontWeight: '500', fontSize: '0.9rem'}}>{format((slotLivraison.start.toDate()), 'HH:mm', { locale: fr })} - {format((slotLivraison.end.toDate()), 'HH:mm', { locale: fr })}</p> : <p style={{fontWeight: '500', fontSize: '0.9rem'}}>{premiereDateLivraison && format((premiereDateLivraison.start.toDate()), 'HH:mm', { locale: fr })} - {premiereDateLivraison && format((premiereDateLivraison.end.toDate()), 'HH:mm', { locale: fr })}</p>}
                    </div>
                </div>
            </div>
              </div>
        </div>
        </>}

        <div className="d-flex flex-md-row flex-column align-items-start py-3 mt-4">
          <div className="flex-fill">
            <p className='pb-3' style={{ fontWeight: '600' }}>Prestation</p>
          </div>
          <div className="flex-fill">
            <input 
              className="form-check-input my-1 choixX"
              type="radio"
              name="Standard"
              id="Standard"
              value='Standard'
              onChange={handleChange}
              checked={selectedPrestation === 'Standard'}
            />
            <label class="form-check-label mx-2" for="Standard">Standard</label>
          </div>
          {/* <div className="flex-fill">
            <input 
              className="form-check-input my-1 choixX"
              type="radio"
              name="StandardPlus"
              id="StandardPlus"
              value='StandardPlus'
              onChange={handleChange}
              checked={selectedPrestation === 'StandardPlus'}
            />
            <label class="form-check-label mx-2" for="StandardPlus">Standard Plus</label>
          </div> */}
          <div className="flex-fill">
            <input
              className="form-check-input my-1 choixX"
              type="radio"
              name="Premium"
              id="Premium"
              value='Premium'
              onChange={handleChange}
              checked={selectedPrestation === 'Premium'}
            />
            <label class="form-check-label mx-2" for="Premium">Premium</label>
          </div>
        </div>
        <div style={{paddingBottom: '100px'}}>
          <p className='py-0 mb-3' style={{ fontWeight: '600' }}>Panier</p>
          {options.map((option, index) => (
            <div key={index} className='py-2'>
                <label style={option.selected ? {fontWeight: '550', color: '#008080', borderBottom: '1px solid grey'} : {fontWeight: '550', color: '#333333'}}>
                  <input
                    type="checkbox"
                    checked={option.selected}
                    onChange={() => toggleOption(index)}
                    className='mx-1'
                  />
                  {option.label}
                </label>
                {option.selected && (
                  <div>
                    <div className='border-bottom mb-2'>
                      <button onClick={() => addItem(index)} className='btn my-3' style={{background:'#fff', color: '#008080', border: '1px solid #008080'}}>
                        <FaPlus size={10} color='#008080' className='mb-1'/><span style={{paddingLeft: '10px'}}>Ajouter un article</span>
                      </button>
                    </div>
                    {option.items.map((item, itemIndex) => {
                      const availableItems = items?.[option.id]?.filter(
                        (itemName) => !option.items.some((item) => item.article === itemName.name)
                      );
                      return (
                        <div key={item.id}>
                          <DropdownButton
                            drop='down-centered'
                            style={{display: 'inline-block' }}
                            className='custom-dropdown1 my-2 col-9 col-sm-6 col-md-5 col-lg-3'
                            id='dropdown-button-drop-down-centered'
                            title={item.article === '' ? 'Séléctionner un article' : item.article}
                            onSelect={(selectedItemName) => updateItem(index, itemIndex, 'article', selectedItemName)}
                            onToggle={(isOpen) => {
                              if (!isOpen) {
                                setSearch('');
                              }
                            }}
                          >
                          <Dropdown.Item onClick={(event) => event.stopPropagation()}>
                          <input 
                            type="text"
                            onKeyDown={(e) => e.key === ' ' && e.stopPropagation()}
                            className="form-control" placeholder="ex: chemise sur cintre..." onChange={(e) => setSearch(e.target.value)} value={search}/>
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          {availableItems.filter((doc) => {
                              return search.toLowerCase() === '' ? doc : doc.name.toLowerCase().includes(search.toLowerCase());
                            }).length === 0 && <Dropdown.Item disabled className='text-center' style={{fontStyle: 'italic', pointerEvents: 'none'}}>Aucun article trouvé</Dropdown.Item>}



                            {availableItems.filter((doc) => {
                              return search.toLowerCase() === '' ? doc.name : doc.name.toLowerCase().includes(search.toLowerCase());
                            }).map((availableItem) => (
                              <Dropdown.Item
                                onClick={() => {
                                  setSearch('');
                                }}
                                key={availableItem.name}
                                eventKey={availableItem.name}
                              >
                                {availableItem.name}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                          <input
                            className='mx-2 btn p-1'
                            style={{background:'#FFF', color: '#008080', border: '1px solid #008080', width: '65px'}}
                            type="number"
                            value={item.total_article}
                            min="1"
                            step={1}
                            onChange={(e) =>
                              updateItem(index, itemIndex, 'total_article', parseInt(e.target.value, 10))
                            }
                            onBlur={(e) =>
                              updateItem(index, itemIndex, 'total_article', forceInteger(e.target.value))
                            }
                          />
                          <button onClick={() => removeItem(index, itemIndex)} className='btn btn-danger '><FaTrash /></button>
                        </div>
                      );
                    })}
                  </div>
                )}
            </div>
          ))}
        </div>
        <div className='col confirmed bg-white fixed-bottom border-top d-flex align-items-center justify-content-center justify-content-sm-end px-sm-5'>
          <button className='btn btn-outline-secondary tttt' onClick={onHideAddOrder}>Annuler</button>
          <button className='btn' style={{background: '#008080', color: '#fff', fontWeight: '600'}} onClick={confirmedOrder}>Confirmer la commande</button>
        </div>
        </Modal.Body>
      </Modal>





      {/* PARTIE COMMANDES EN COURS */}
        <div className={!props.stl ? 'page' : 'page page-with-navbar'} onClick={() => props.show(false)}>
          <p className=' m-4 mb-1 h3' style={{ fontWeight: 'bold' }}>Commandes en cours</p>
          <div className='mx-auto' style={{ height: '2px', width: '97%', background: '#F1F1F1' }}></div>

          {customerData && customerData?.status === 1 ? 
          (customerData?.homeDelivery ?
          <div className='container mx-auto'>
                <div className="d-flex justify-content-end align-items-end mt-3" style={{ width: '95%' }}>
                <button className='btn m-0 py-1 my-2' style={{ fontWeight: '200', cursor: 'pointer', display: 'inline', color: '#008080', background: '#fff', border: '1px solid #008080'}} onClick={async() => {setShowAddOrder(showAddOrder => true); 
                  }}><FiEdit className='mx-1 mb-1'/> Ajouter une collecte</button>
                </div>
          </div>
          :
            <div className='container mx-auto'>
            <div className='row'>

              <div className='col'>
                <p className=' m-4 mb-1 p-1 h5' style={{ fontWeight: '600' }}>Passage en cours</p>
                <div className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2' style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}>

                <div className="d-flex justify-content-between align-items-center">
                  <p className='pt-2 text-capitalize' style={{ fontWeight: '600' }}>{customerData.slots_passages && formatDate2(customerData.slots_passages[0].toDate().toISOString())}</p>
                    {/* <AddOrder
                      icon={<FiEdit className='mx-1'/>}
                      onClick={() => setShowAddOrder(showAddOrder => true)}
                    /> */}
                </div>

                  <p className='pt-3' style={{ fontWeight: '400' }}>Collecte/livraison : <span style={{ fontWeight: '200' }}>{customerData.adressLivraisonCompany}</span></p>
                  {(customerData?.underAccount || (!customerData?.underAccount && filterListByDate().length === 0)) && <button className='btn m-0 py-1 my-2' style={{ fontWeight: '200', cursor: 'pointer', display: 'inline', color: '#008080', background: '#fff', border: '1px solid #008080'}} onClick={async() => {setShowAddOrder(showAddOrder => true); 
                  //getCustomer()
                  }}><FiEdit className='mx-1 mb-1'/> Ajouter une collecte</button>}
                </div>
              </div>

              <div className='col'>
                <p className=' m-4 mb-1 p-1 h5' style={{ fontWeight: '600' }}>Prochain passage</p>
                <div className='mx-auto px-4 py-3 my-2' style={{ background: '#F1F1F1', width: '95%', borderRadius: '5px' }}>
                <p className='pt-2 text-capitalize' style={{ fontWeight: '600' }}>{customerData.slots_passages && formatDate2(customerData.slots_passages[1].toDate().toISOString())}</p>
                  <p className='pt-3' style={{ fontWeight: '400' }}>Collecte/livraison : <span style={{ fontWeight: '200' }}>{customerData.adressLivraisonCompany}</span></p>
                  <p className='mb-0' style={{ fontWeight: '400' }}>Horaire : <span style={{ fontWeight: '200' }}>{customerData.slot_start} - {customerData.slot_end}</span></p>
                  </div>
              </div>
            </div>
          </div>)
          :
          <p className={`text-center py-4 mx-auto ${customerData?.status === 2 ? 'text-danger' : customerData?.status === 3 ? 'text-warning' : 'text-secondary'}`} style={{fontStyle: 'italic'}}>Votre compte est {customerData?.status === 2 ? 'archivé' : customerData?.status === 3 ? 'en pause' : '{error}'}</p>}

 









          {!customerData?.homeDelivery && filterListByDate().length > 0 &&
            <div style={{ width: '95%', borderRadius: '5px', border: '1px solid #008080' }} className="pt-2 pb-2 container mx-auto my-3 bg-light">
                <div className="row justify-content-between">
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    <p style={{ fontWeight: '600' }}>Récupération : <span style={{ fontWeight: '200' }}>{formatDate(filterListByDate()[0]?.date_collecte.toDate().toISOString())}</span></p>
                  </div>
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    <p style={{ fontWeight: '600' }}>Livraison : <span style={{ fontWeight: '200' }}>{formatDate(customerData.slots_passages[1].toDate().toISOString())}</span></p>
                  </div>
                </div>

                <div className="row justify-content-between">
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    {customerData?.underAccount ?
                      <p style={{ fontWeight: '600' }}>Total de dépot : <span style={{ fontWeight: '200' }}>{filterListByDate()[0]?.under_user?.length}</span></p>
                    :
                    <p style={{ fontWeight: '600' }}>N° de commande : <span style={{ fontWeight: '200' }}>{filterListByDate()[0]?.under_user?.[0]?.number_order}</span></p>}
                    <p style={{ fontWeight: '600' }}>Statut : <span style={{ fontWeight: '200' }}>{getStatusOrder(filterListByDate()[0]?.etat_commande)}</span></p>
                  </div>

                  <div className='col-sm-12 col-md-6 align-self-center'>
                  <button type="button" className="btn m-0 p-0" style={{color: '#0070c9', textDecoration: 'underline'}} onClick={() => handleShow(filterListByDate()[0]?.id)}>Voir la commande en cours</button>
                  </div>
                </div>





          <div class="d-flex justify-content-between flex-wrap mt-3 mx-2">
          <p className='mb-0' style={{ fontWeight: '500' }}>Pressing / Blanchisserie <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersPressing}</Badge></p>

          <p className='mb-0' style={{ fontWeight: '500' }}>Retouche <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersRetouche}</Badge></p>

          <p className='mb-0' style={{ fontWeight: '500' }}>Cordonnerie <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersCordonnerie}</Badge></p>

          <p className='mb-0' style={{ fontWeight: '500' }}>Linge au kilo <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersLk}</Badge></p>
          </div>
          
            <p className='mb-0 mx-2 text-secondary' style={{ fontWeight: '200', fontStyle: 'italic'}}>Le passage est prévue entre {customerData.slot_start} et {customerData.slot_end}</p>
          </div>}

          {orders.filter(order => { 
            return !customerData?.homeDelivery && order.etat_commande !== 6 && order.etat_commande !== 5 && filterListByDate()[0]?.id !== order.id}).slice(startIndexEnligne, endIndexEnLigne).map(orderFilter => {
                return (
                  <div style={{ width: '95%', borderRadius: '5px', border: '1px solid #008080' }} className="pt-2 pb-2 container mx-auto my-3 bg-light">
                <div className="row justify-content-between">
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    <p style={{ fontWeight: '600' }}>Récupération : <span style={{ fontWeight: '200' }}>{formatDate(orderFilter.date_collecte.toDate().toISOString())}</span></p>
                  </div>
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    <p style={{ fontWeight: '600' }}>Livraison : <span style={{ fontWeight: '200' }}>{formatDate(getLivraisonDate(orderFilter)[0].date_livraison.toDate().toISOString())}</span></p>
                  </div>
                </div>

                <div className="row justify-content-between">
                  <div className='col-sm-12 col-md-6 align-self-center'>
                  {customerData?.underAccount ?
                      <p style={{ fontWeight: '600' }}>Total de dépot : <span style={{ fontWeight: '200' }}>{orderFilter.under_user?.length}</span></p>
                    :
                    <p style={{ fontWeight: '600' }}>N° de commande : <span style={{ fontWeight: '200' }}>{orderFilter.under_user?.[0]?.number_order}</span></p>}

                    <p style={{ fontWeight: '600' }}>Statut : <span style={{ fontWeight: '200' }}>{areAllEtatSecondEmpty(orderFilter.under_user) ? getStatusOrder(orderFilter.etat_commande) : Number(orderFilter.under_user?.[findIndexOfSmallestetatSecond(orderFilter.under_user)].etat_second) === 6 ? getStatusOrder(orderFilter.etat_commande) : getStatusOrder(Number(orderFilter.under_user?.[findIndexOfSmallestetatSecond(orderFilter.under_user)].etat_second))}</span></p>
                  </div>

                  <div className='col-sm-12 col-md-6 align-self-center'>
                  <button type="button" className="btn p-0 m-0" onClick={() => handleShow(orderFilter.id)}><FaEye size={18} color='#008080'/></button>
                  </div>
                </div>

          <div class="d-flex justify-content-between flex-wrap mt-3 mx-2">
          <p className='mb-0' style={{ fontWeight: '500' }}>Pressing / Blanchisserie <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersPressingCustomer(orderFilter)}</Badge></p>

          <p className='mb-0' style={{ fontWeight: '500' }}>Retouche <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersRetoucheCustomer(orderFilter)}</Badge></p>

          <p className='mb-0' style={{ fontWeight: '500' }}>Cordonnerie <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersCordonnerieCustomer(orderFilter)}</Badge></p>

          <p className='mb-0' style={{ fontWeight: '500' }}>Linge au kilo <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersLkCustomer(orderFilter)}</Badge></p>
          </div>
          
            <p className='mb-0 mx-2 text-secondary' style={{ fontWeight: '200', fontStyle: 'italic'}}>Le passage est prévue entre {customerData?.slot_start} et {customerData?.slot_end}</p>
          </div>
            )
          })}

          {orders.filter(order => { 
            return customerData?.homeDelivery && order.etat_commande !== 6 && order.etat_commande !== 5 }).map(orderFilter => {
                return (
                  <div style={{ width: '95%', borderRadius: '5px', border: '1px solid #008080' }} className="pt-2 pb-2 container mx-auto my-3 bg-light">
                <div className="row justify-content-between">
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    <p style={{ fontWeight: '600' }}>Récupération : <span style={{ fontWeight: '200' }}>
                    {
                    (orderFilter.date_collecte && orderFilter.slot_collecte_end ?
                        `${format(orderFilter.date_collecte?.toDate(), 'EEE dd MMM', { locale: fr })} | ${format((orderFilter.date_collecte?.toDate()), 'HH:mm', { locale: fr })} - ${format((orderFilter.slot_collecte_end?.toDate()), 'HH:mm', { locale: fr })}` : '-'
                    )
                    }</span></p>
                  </div>
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    <p style={{ fontWeight: '600' }}>Livraison : <span style={{ fontWeight: '200' }}>
                    {
                    (orderFilter.under_user?.[0]?.date_livraison && orderFilter.under_user?.[0]?.slot_livraison_end ?
                        `${format(orderFilter.under_user?.[0].date_livraison.toDate(), 'EEE dd MMM', { locale: fr })} | ${format((orderFilter.under_user?.[0].date_livraison.toDate()), 'HH:mm', { locale: fr })} - ${format((orderFilter.under_user?.[0].slot_livraison_end?.toDate()), 'HH:mm', { locale: fr })}` : '-'
                    )
                    }
                    </span></p>
                  </div>
                </div>

                <div className="row justify-content-between">
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    {(customerData?.homeDelivery || !customerData?.underAccount)?
                      <p style={{ fontWeight: '600' }}>N° de commande : <span style={{ fontWeight: '200' }}>{orderFilter.under_user?.[0]?.number_order}</span></p>
                      :
                      <p style={{ fontWeight: '600' }}>Total de dépot : <span style={{ fontWeight: '200' }}>{orderFilter.under_user?.length}</span></p>}
                    <p style={{ fontWeight: '600' }}>Statut : <span style={{ fontWeight: '200' }}>{getStatusOrder(orderFilter.etat_commande)}</span></p>
                  </div>

                  <div className='col-sm-12 col-md-6 align-self-center'>
                  {orderFilter.etat_commande === 1 ? <button type="button" style={{color: '#0070c9', textDecoration: 'underline'}} className="btn p-0 m-0" onClick={() => handleShow(orderFilter.id)} >Voir la commande en cours</button> : <FaEye style={{cursor: 'pointer'}} size={18} color='#008080' onClick={() => handleShow(orderFilter.id)}/>}
                  {/* <button type="button" className="btn p-0 m-0" onClick={() => handleShow(orderFilter.id)}>{orderFilter.etat_commande === 1 ? 'Voir la commande en cours' : <FaEye size={18} color='#008080'/>}</button> */}
                  </div>
                </div>

          <div class="d-flex justify-content-between flex-wrap mt-3 mx-2">
          <p className='mb-0' style={{ fontWeight: '500' }}>Pressing / Blanchisserie <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersPressingCustomer(orderFilter)}</Badge></p>

          <p className='mb-0' style={{ fontWeight: '500' }}>Retouche <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersRetoucheCustomer(orderFilter)}</Badge></p>

          <p className='mb-0' style={{ fontWeight: '500' }}>Cordonnerie <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersCordonnerieCustomer(orderFilter)}</Badge></p>

          <p className='mb-0' style={{ fontWeight: '500' }}>Linge au kilo <Badge bg="light" className='text-dark border mx-1'>{filteredOrdersLkCustomer(orderFilter)}</Badge></p>
          </div>
            {!customerData?.homeDelivery && <p className='mb-0 mx-2 text-secondary' style={{ fontWeight: '200', fontStyle: 'italic'}}>Le passage est prévue entre {customerData?.slot_start} et {customerData?.slot_end}</p>}
          </div>
            )
          })}
          <div className='w-100 mt-3'>
            <div className="d-flex justify-content-end align-items-center mx-3">
            <button onClick={() => changePageEnLigne(-1)} disabled={currentPageEnLigne === 1} className='btn ' style={{color: '#008080', background: '#F1F1F1'}}>
          Précédent
        </button>
        <span className='mx-2'>
          {currentPageEnLigne} / {totalPagesEnLigne}
        </span>
        <button
          className='btn ' style={{color: '#008080', background: '#F1F1F1'}}
          onClick={() => changePageEnLigne(1)}
          disabled={currentPageEnLigne === totalPagesEnLigne}
        >
          Suivant
        </button>
        </div>
</div>
















          {/* PARTIE 2 HISTORIQUE */}
          <p className=' m-4 mb-1 h3' style={{ fontWeight: 'bold' }}>Anciennes commandes</p>
          <div className='mx-auto' style={{ height: '2px', width: '97%', background: '#F1F1F1' }}></div>
          <input 
          type="text" 
          className="form-control my-4 mx-auto" 
          style={{width: '60%'}}
          placeholder="Numéro de commande, nom, prénom..." 
          onChange={(e) => setSearchOrder(e.target.value)} />
          
          <div className='mx-auto mb-2 pb-2' style={{ width: '95%', borderRadius: '5px' }}>
            {orders.filter(order => order.etat_commande === 6 || order.etat_commande === 5).filter(doc => {
    return doc.under_user.some(user =>
      searchOrder.toLowerCase() === ''
        ? true
        : (user.name.toLowerCase().includes(searchOrder.toLowerCase()) || user.number_order.toLowerCase().includes(searchOrder.toLowerCase())));
  }).length === 0 && orders.filter(order => order.etat_commande === 6 || order.etat_commande === 5).length > 0 && <p className='text-center my-3 text-secondary'>Aucun résultat trouvé</p>}
            {orders.filter(order => order.etat_commande === 6 || order.etat_commande === 5).length === 0 && <p className='text-center my-3 mx-1 text-secondary' style={{fontStyle: 'italic'}}>Vous n'avez pas de commande.</p>}
            {orders.filter(order => order.etat_commande === 6 || order.etat_commande === 5).filter(doc => {
    return doc.under_user.some(user =>
      searchOrder.toLowerCase() === ''
        ? true
        : (user.name.toLowerCase().includes(searchOrder.toLowerCase()) || user.number_order.toLowerCase().includes(searchOrder.toLowerCase())));
  }).slice(startIndex, endIndex).map((doc) => {

              return (
                <div key={doc.id} style={{ borderBottom: '2px solid #F1F1F1' }} className="pt-2 pb-2">
                <div className="row justify-content-between">
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    <p style={{ fontWeight: '600' }}>Récupération : <span style={{ fontWeight: '200' }}>
                    {customerData?.homeDelivery ? 
                    (
                        `${format(doc.date_collecte.toDate(), 'EEE dd MMM', { locale: fr })} | ${format((doc.date_collecte.toDate()), 'HH:mm', { locale: fr })} - ${format((doc.date_collecte.toDate()), 'HH:mm', { locale: fr })}`
                    ): 
                    (
                      format(doc.date_collecte.toDate(), 'EEE dd MMM', { locale: fr })
                    )
                    }
                    </span></p>
                  </div>
                  <div className='col-sm-12 col-md-6 align-self-center'>
                    <p style={{ fontWeight: '600' }}>Livraison : <span style={{ fontWeight: '200' }}>

                    {customerData?.homeDelivery ?
                      (doc.under_user?.[0]?.date_livraison && doc.under_user?.[0]?.slot_livraison_end ?
                        `${format(doc.under_user?.[0]?.date_livraison?.toDate(), 'EEE dd MMM', { locale: fr })} | ${format((doc.under_user?.[0]?.date_livraison?.toDate()), 'HH:mm', { locale: fr })} - ${format((doc.under_user?.[0]?.slot_livraison_end?.toDate()), 'HH:mm', { locale: fr })}` : '-'
                      )
                      :
                      (doc.under_user?.[underCustomer]?.date_livraison ?
                      format(getLivraisonDate(doc)?.[0]?.date_livraison?.toDate(), 'EEE dd MMM', { locale: fr }) : '-'
                    )
                    }
                    </span></p>
                  </div>
                </div>

                <div className="row justify-content-between">
                <div className='col-sm-12 col-md-6 align-self-center'>
                {(customerData?.homeDelivery || !customerData?.underAccount)?
                      <p style={{ fontWeight: '600' }}>N° de commande : <span style={{ fontWeight: '200' }}>{doc.under_user?.[0]?.number_order}</span></p>
                      :
                      <p style={{ fontWeight: '600' }}>Total de dépot : <span style={{ fontWeight: '200' }}>{doc.under_user?.length}</span></p>}
                    <p style={{ fontWeight: '600' }}>Statut : <span style={{ fontWeight: '600', color: `${doc.etat_commande === 6 ? 'red' : '#3BACB6'}` }}>{areAllEtatSecondEmpty(doc.under_user) ? getStatusOrder(doc.etat_commande) : Number(doc.under_user?.[findIndexOfSmallestetatSecond(doc.under_user)].etat_second) === 6 ? getStatusOrder(doc.etat_commande) : getStatusOrder(Number(doc.under_user?.[findIndexOfSmallestetatSecond(doc.under_user)].etat_second))}</span></p>
                  </div>
                  <div className='col-sm-12 col-md-6 align-self-center'>
                  {doc.etat_commande !== 6 && <button type="button" className="btn m-0 p-0" style={{color: '#0070c9', textDecoration: 'underline'}} onClick={() => handleShow(doc.id)}>Voir détail de la commande</button>}
                  </div>
                </div>
                </div>
              )
            })}
          </div>

          <div className='w-100 mb-5 mt-3'>
            <div className="d-flex justify-content-end align-items-center mx-3 mb-5">
            <button onClick={() => changePage(-1)} disabled={currentPage === 1} className='btn ' style={{color: '#008080', background: '#F1F1F1'}}>
          Précédent
        </button>
        <span className='mx-2'>
          {currentPage} / {totalPages}
        </span>
        <button
          className='btn ' style={{color: '#008080', background: '#F1F1F1'}}
          onClick={() => changePage(1)}
          disabled={currentPage === totalPages || totalPages === 0}
        >
          Suivant
        </button>
        </div>
</div>
        </div>
        </>
  )
}
