import React, { useEffect, useState } from 'react';
import '../../../style/index.css'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { IoEllipse } from 'react-icons/io5';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase-config';

export default function Profil(props) {
  const [customerData, setCustomerData] = useState(null);
  useEffect(() => {
			const unsubscribe = onSnapshot(doc(db, "corporate", props.uid), (doc) => {
				setCustomerData(doc.data());
				//setIsLoading(false);
			});
		  
			  return () => unsubscribe(); 
	}, []);


  // useEffect(() => {
  //   if (props.customer) {
  //     setCustomerData(props.customer);
  //   }
  // }, [props.customer]);

  const formatDate = (dateString) => {
    const options = { weekday: 'long' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }
  const statusAccount = () => {
      if (!customerData) {
    return null;
  }
    switch (customerData.status) {
      case 1 : return <p style={{fontWeight: '500', fontStyle: 'italic', fontSize: '12px'}}><IoEllipse className='text-success mb-1' size={10}/> Activé</p>; break;
      case 2 : return <p style={{fontWeight: '500', fontStyle: 'italic', fontSize: '12px'}}><IoEllipse className='text-danger mb-1' size={10}/> Archivé</p>; break;
      case 3 : return <p style={{fontWeight: '500', fontStyle: 'italic', fontSize: '12px'}}><IoEllipse className='text-warning mb-1' size={10}/> En pause</p>; break;
      default : return '';
    }
  }


    return (
        <div className={!props.stl ? "page" : "page page-with-navbar"} onClick={() => props.show(false)}>
<div className='container'>        <div className='row'>
          <div className='col text-end'>
          <div className='p-3 pt-1 pb-1' style={{background:'#F1F1F1', borderRadius: '5px', display: 'inline-block'}}>
          {statusAccount()}
        </div>
          </div>
        </div></div>
        
          
        {/* {isLoading ? 
        <>
        <div className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2 py-3 bg-light' style={{width: '95%', borderRadius: '5px'}}>
          <Skeleton style={{background: '#F1F1F1'}}/>
        </div>

        <div className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2 py-3 bg-light' style={{width: '95%', borderRadius: '5px'}}>
          <Skeleton count={3} style={{background: '#F1F1F1'}}/>
        </div>
        <div className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2 py-3 bg-light' style={{width: '95%', borderRadius: '5px'}}>
          <Skeleton style={{background: '#F1F1F1'}}/>
        </div>

        <div className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2 py-3 bg-light' style={{width: '95%', borderRadius: '5px'}}>
          <Skeleton count={4} style={{background: '#F1F1F1'}}/>
        </div>
        <div className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2 py-3 bg-light' style={{width: '95%', borderRadius: '5px'}}>
          <Skeleton style={{background: '#F1F1F1'}}/>
        </div>
        <div className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2 py-3 bg-light' style={{width: '95%', borderRadius: '5px'}}>
          <Skeleton count={6} style={{background: '#F1F1F1'}}/>
        </div>
        </>


        :
        <> */}
{customerData && <>
  <p className=' m-4 mb-1 h3' style={{fontWeight: 'bold'}}>Mes informations</p>
          <div className='mx-auto' style={{height: '2px', width: '97%', background:'#F1F1F1'}}></div>
          <p className=' m-4 mb-1 p-1 h5' style={{fontWeight: '600'}}>Personne de contact</p>
          <div className='mx-auto p-4 pt-2 pb-2 mt-2 mb-2' style={{background:'#F1F1F1', width: '95%', borderRadius: '5px'}}>
            <p className='pt-2' style={{fontWeight: '700'}}>{customerData.nameContact}</p>
            <p className='pt-3' style={{fontWeight: '200'}}>{customerData.emailContact}</p>
            <p className='pb-2' style={{fontWeight: '200'}}>{customerData.telContact}</p>
          </div>

          <p className=' m-4 mb-1 p-1 h5' style={{fontWeight: '600'}}>Services</p>
          <div className='mx-auto p-3 py-2 my-2' style={{background:'#F1F1F1', width: '95%', borderRadius: '5px'}}>
            <div className="container">
              <div className="row">
              {customerData.services && customerData.services.map((doc, index) => {
                return (
                  <div key={index} className='col-md-auto p-0 pt-2' style={{fontWeight: '700'}}>{doc}{index !== customerData.services.length-1 && ' |'}</div>
                );
                })
              }
              </div>
            </div>
            {!customerData.homeDelivery &&             <div className="container pt-3 px-0 mx-0">
              <div className="row" style={{fontWeight: '200'}}>
                <p className='col-md-auto'>Passage automatique : </p>
              {!customerData.homeDelivery && customerData.status === 1 && customerData.slots_passages && customerData.slots_passages.map((doc, index) => {
                return (
                  <p key={index} className='col-md-auto text-capitalize px-2 px-md-0' style={{display:'inline'}}>{formatDate(doc.toDate().toISOString())}{index !== customerData.slots_passages.length-1 && ' -'}&nbsp;</p>
                );
                })
              }
              {(customerData.status !== 1 && !customerData.homeDelivery) && 
                  <p className='col-md-auto px-2 px-md-0' style={{display:'inline'}}>Votre compte est {customerData.status === 2 ? 'archiver' : 'en pause'}</p>
              }
              </div>
              <div className="row pb-2" style={{fontWeight: '200'}}>
                <p className='col-md-auto'>Créneaux : {customerData && customerData.slot_start} - {customerData && customerData.slot_end}</p>
              </div>
            </div>}
            <div className="row pb-2" style={{fontWeight: '200'}}>
            <p className='col-md-auto' style={{fontWeight: '200'}}>Paiement : {customerData.paiement}</p>
              </div>
            
          </div>

          <p className=' m-4 mb-1 p-1 h5' style={{fontWeight: '600'}}>Informations sur l'entreprise</p>
          <div className='mx-auto p-4 pt-2 pb-2 mt-2 mb-5' style={{background:'#F1F1F1', width: '95%', borderRadius: '5px'}}>
          <p className='pt-2' style={{fontWeight: '700', textTransform: 'uppercase'}}>{customerData.nameCompany}</p>
          <p className='pt-3' style={{fontWeight: '200'}}>{customerData.emailCompany}</p>
          <p style={{fontWeight: '200'}}>{customerData.telCompany}</p>
          <p className='pb-3' style={{fontWeight: '200'}}>Siren : {customerData.siretCompany}</p>

          <p className='pb-2' style={{fontWeight: '400'}}>Facturation</p>
          <p style={{fontWeight: '200'}}>Adresse : {customerData.adressInvoiceCompany}</p>
          <p className='pb-3' style={{fontWeight: '200'}}>Adresse mail : {customerData.emailInvoiceCompany}</p>
          {!customerData.homeDelivery && 
          <>
          <p className='pb-2' style={{fontWeight: '400'}}>Livraison</p>
          <p className='pb-3' style={{fontWeight: '200'}}>Adresse : {customerData.adressLivraisonCompany}</p>
          </>}
         

          {/* <p className='pb-2' style={{fontWeight: '400'}}>Moyen de paiement</p>
          <p className='pb-3' style={{fontWeight: '200'}}>{customer.tokenCard}</p> */}
          </div>
</>}
        {/* </>} */}
        </div>
    );
}