import { collection, getDocs, query, where, orderBy, doc, getDoc, addDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "../firebase-config";

const underAccountCollectionRef = collection(db, "customers_corporate_underAccount");

class UnderAccountDataService {
  getAllUnderAccount = (id) => {
    const q = query(underAccountCollectionRef, where("id_company", "==", id), orderBy("date_create", "desc"));
    return getDocs(q);
  };
  getUnderAccount= (id) => {
    const underAccountDoc = doc(db, "customers_corporate_underAccount", id);
    return getDoc(underAccountDoc);
  };
  getItems= () => {
    const itemsDoc = doc(db, "tarifs", 'waw');
    return getDoc(itemsDoc);
  };
  addUnderUser = (data) => {
    return addDoc(underAccountCollectionRef, data);
  }
  updateAdressUnderAccount = (newAddresses, idDoc) => {
    return updateDoc(doc(db, "customers_corporate_underAccount", idDoc), {
      addresses: newAddresses
    }).then(() => {
      const docRef = doc(db, "customers_corporate_underAccount", idDoc);
      newAddresses.forEach((note) => {
        updateDoc(docRef, {
          notes: arrayUnion(note),
        });
      });
    });;
  };
}

export default new UnderAccountDataService();