import { collection, getDocs, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase-config";

const adminsCollectionRef = collection(db, "corporate");

class AdminDataService {

  getAllAdmins = () => {
    return getDocs(adminsCollectionRef);
  };
  getCustomer = (id) => {
    const costumerDoc = doc(db, "corporate", id);
    return getDoc(costumerDoc);
  };
  updateSlotCostumer = (id, updatedSlot) => {
    const costumerSlotDoc = doc(db, "corporate", id);
    return updateDoc(costumerSlotDoc, {
      slots_passages: updatedSlot
    });
  };
}

export default new AdminDataService();