import React, { useEffect, useState } from 'react';
import '../../../style/index.css';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../firebase-config';
import { FaEye } from 'react-icons/fa';

export default function UnderAccount(props) {
  const [underAccount, setUnderAccount] = useState([]);
  const [searchUser, setSearchUser] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(underAccount.filter((doc) => {
    return searchUser.toLowerCase() === '' ? doc : doc.last_name.toLowerCase().includes(searchUser.toLowerCase()) || doc.first_name.toLowerCase().includes(searchUser.toLowerCase()) || doc.email.toLowerCase().includes(searchUser.toLowerCase());
  }).length / 10);
  const changePage = (direction) => {
    setCurrentPage((prevPage) => {
      let newPage = prevPage + direction;
      if (newPage < 1) {
        newPage = 1;
      } else if (newPage > totalPages) {
        newPage = totalPages;
      }
      return newPage;
    });
  };
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(dateString).toLocaleDateString('fr-FR', options)
  }

  useEffect(() => {
    const q = query(collection(db, "customers_corporate_underAccount"), where("id_company", "==", props.uid), orderBy("date_create", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const invoices = [];
        querySnapshot.forEach((doc) => {
        invoices.push(doc.data());
  });
  setUnderAccount(invoices);
  });

    return () => unsubscribe(); 
  }, []);

  return (
      <div className={!props.stl ? "page" : "page page-with-navbar"} onClick={() => props.show(false)}>
        <p className=' m-4 mb-1 h3' style={{fontWeight: 'bold'}}>Mes sous-comptes</p>
        <div className='mx-auto' style={{height: '2px', width: '97%', background:'#F1F1F1'}}></div>

        <input 
          type="text" 
          className="form-control my-3 mx-auto" 
          style={{width: '60%'}}
          placeholder="Numéro de commande, nom, prénom..." 
          onChange={(e) => setSearchUser(e.target.value)} />

        
{underAccount.filter((doc) => {
  return searchUser.toLowerCase() === '' ? doc : doc.last_name.toLowerCase().includes(searchUser.toLowerCase()) || doc.first_name.toLowerCase().includes(searchUser.toLowerCase()) || doc.email.toLowerCase().includes(searchUser.toLowerCase());
}).slice(startIndex, endIndex).length === 0 && underAccount.length > 0 && <p className='mx-auto pt-3 text-center text-secondary' style={{fontWeight: '400'}}>Aucun compte trouver</p>}
        {underAccount.length === 0 ? <p className='text-center my-3 mx-auto text-secondary' style={{fontStyle: 'italic'}}>Vous n'avez pas encore de sous-compte.</p> : <table className="table mx-auto mt-3 tableInvoice" style={{width: '90%'}}>
  <thead>
    <tr >
      <th scope="col" className='col-md-auto p-2' style={{color: '#303030'}}>Date de création</th>
      <th scope="col" className='col-md-auto p-2 text-center' style={{color: '#303030'}}>Nom</th>
      <th scope="col" className='col-md-auto p-2 text-center' style={{color: '#303030'}}>Prénom</th>
      {/* <th scope="col" className='col-md-auto p-2 text-center' style={{color: '#303030'}}>{props.customer.homeDelivery ? 'Action' : 'E-mail'}</th> */}
    </tr>
  </thead>
  <tbody>
    {underAccount.filter((doc) => {
  return searchUser.toLowerCase() === '' ? doc : doc.last_name.toLowerCase().includes(searchUser.toLowerCase()) || doc.first_name.toLowerCase().includes(searchUser.toLowerCase()) || doc.email.toLowerCase().includes(searchUser.toLowerCase());
}).slice(startIndex, endIndex).map((doc) => {
      return(
        <tr key={doc.id}>
          <td style={{color: '#8f8f8f'}} className=' p-2'>{formatDate(doc.date_create.toDate().toISOString())}</td>
          <td style={{color: '#8f8f8f'}} className=' p-2 text-center'>{doc.last_name}</td>
          <td style={{color: '#8f8f8f'}} className=' p-2 text-center'>{doc.first_name}</td>
          {/* <td style={{color: '#8f8f8f'}} className=' p-2 text-center'>{props.customer.homeDelivery ? <FaEye color='#008080'/> : doc.email}</td> */}
        </tr>

      )
    })}
    </tbody>
</table>}
         <div className='w-100 mb-5 mt-3'>
            <div className="d-flex justify-content-end align-items-center mx-3 mb-5">
            <button onClick={() => changePage(-1)} disabled={currentPage === 1} className='btn ' style={{color: '#008080', background: '#F1F1F1'}}>
          Précédent
        </button>
        <span className='mx-2'>
          {currentPage} / {totalPages}
        </span>
        <button
          className='btn ' style={{color: '#008080', background: '#F1F1F1'}}
          onClick={() => changePage(1)}
          disabled={currentPage === totalPages}
        >
          Suivant
        </button>
        </div>
</div>
      </div>
    );
}