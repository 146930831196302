import { sendPasswordResetEmail } from "firebase/auth";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { auth } from "../../firebase-config";
import "./connexion.css";

function ModalPopup({ setOpenModal }) {
    const [emailReset, setEmailReset] = useState("")
    const { enqueueSnackbar } = useSnackbar();
    
    const sendPsw = async () => {
      try{
        await sendPasswordResetEmail(auth, emailReset);
        enqueueSnackbar("Le mail vient d'être envoyé, veuillez verifier votre adresse e-mail.", { 
          variant: 'success',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          autoHideDuration: 3000,
         
        });
        setTimeout(() => {
          setEmailReset("")
        }, 3000)
        
  
      } catch {
        enqueueSnackbar("L'adresse e-mail saisie est incorrecte.", { 
          variant: 'error',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          autoHideDuration: 3000,
         
        });
      }
    }
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
          className="mx-4 my-3"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="text-center">
          <p style={{fontWeight: '700', fontSize: '19px'}}>Mot de passe oublié</p>
        </div>
        <div className="pt-5 col">
        <p className="text-center px-3 px-md-5 pb-3">
          Nous vous enverrons un e-mail pour réinitialiser votre mot de passe.
        </p>
        <div className="d-grid gap-2 mx-md-5 mt-3 mx-4">
        <Form.Control type="email" placeholder="Saisir votre e-mail" className="px-3 py-1" required  value={emailReset} onChange={e => setEmailReset(e.target.value)}/>
        </div>
        <div className="d-grid gap-2 mx-md-5 mt-3 mx-4">
          <Button variant="btn-block text-white px-3 py-1" type="button" onClick={sendPsw} style={{background: '#008080'}}>
            Envoyer l'e-mail
          </Button>
        </div>
        <div className="d-grid gap-2 mt-4 mx-5 px-5">
        <button
          className="btn btn-outline-danger mx-5 px-3 py-1"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Annuler
          </button>
        </div>

        </div>
      </div>
    </div>
  );
}

export default ModalPopup;