import img404 from "../../assets/Images/404.png";
import React, {useContext} from 'react';
import {useNavigate} from "react-router-dom";
import { UserContext } from '../../context/userContext';

function Page404(props) {
    const {currentUser} = useContext(UserContext);
    const navigate = useNavigate();
    const onClick = () => {
        navigate("/")
    }
    return(
        <div className={!props.stl ? "page" : "page page-with-navbar"} onClick={() => props.show(false)}>
        <img src={img404} className="img-fluid d-block rounded mx-auto" alt="présente l'erreur 404 d'une page qui n'existe pas"></img>
            <h6 className="p-5 text-center mx-auto">Nous sommes désolés, mais la page que vous recherchez est introuvable ou n'existe pas</h6>
            <div className="d-grid gap-2 col-md-2 mx-auto text-center">
                <button type="button" className="btn p-2" style={{ color:'white', background: '#008080'}} onClick={onClick}>Page principale</button>
            </div>
        </div>
    )
}
export default Page404